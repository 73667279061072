<template>
  <div :class="isMobile ? 'cloud7_table_mobile' : 'table-children'">
    <div class="row invest-compare-table">
      <a-table
        :class="columns.length < 6 ? 'cross-table' : ''"
        :columns="columns"
        :data-source="tableData"
        :pagination="false"
        :custom-row="customRow"
        :customRender="customRender"
        :scroll="{ x: isMobile ? true : null }"
        size="small"
        bordered
        @resizeColumn="
          (w, col) => {
            col.width = w
          }
        "
      >
        <template #bodyCell="{ column, text, record, index, ...others }">
          <template v-if="column.dataIndex.indexOf('item_') > -1">
            <!-- {{ $dealThousands(text) }} -->
            {{ text !== '' ? $dealThousands(text) : '' }}
          </template>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'dataTableCompare',
  components: {},
  props: {
    columns: {
      type: Array,
      default: () => []
    },
    tableData: {
      type: Array,
      default: () => []
    },
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  computed: {},
  data() {
    return {}
  },
  mounted() {},
  watch: {},
  methods: {
    customRow(record, index) {
      // 检查当前行的 name 字段是否为空字符串
      if (record.name === '') {
        // 如果是，则返回一个对象，其中包含你想要设置的样式
        return {
          style: {
            height: '5px' // 设置你需要的行高
          }
        }
      }
      // 如果不是，则不返回任何样式，或者返回默认样式
      return {}
    }
    // customCell(record, rowIndex, column) {
    //   console.log('===========record, rowIndex, column', record, rowIndex, column)
    // }
  }
}
</script>
<style lang="scss">
.cloud7_table_mobile {
  .ant-table-wrapper .ant-table-thead > tr > th {
    background-color: var(--themeColor);
    color: #fff;
    font-weight: normal;
    font-size: 12px;
    text-align: center !important;
    padding: 2px 0px !important;
  }
  .ant-table-wrapper .ant-table.ant-table-bordered .ant-table-tbody > tr > td {
    padding: 2px 8px;
  }
  .ant-table-cell {
    font-size: 12px;
  }
}
</style>