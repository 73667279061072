<template>
  <div class="text-red">* 本表只适用于原始导入数据的经营情况分析</div>
  <slot name="clickTo"></slot>
  <a-table class="swiper-no-swiping" ref="tableList" :columns="getTableColumns" :data-source="tableData" :pagination="pagination" size="small" bordered :scroll="isMobile ? { x: 400 } : {}">
    <template #bodyCell="{ column, record, text }">
      <template v-if="['customer', 'no', 'customerNo', 'pass_per', 'ac_per', 'ac_per2', 'ac_per3'].indexOf(column.dataIndex) === -1">
        {{ handleNumber(text) }}
      </template>
    </template>

    <template #summary>
      <a-table-summary fixed>
        <a-table-summary-row class="gray-bg-def-deep">
          <a-table-summary-cell :index="0" align="center" v-show="getTableColumnsKeys.indexOf('no') > -1"> 总计 </a-table-summary-cell>
          <a-table-summary-cell align="center" v-show="getTableColumnsKeys.indexOf('customer') > -1"> / </a-table-summary-cell>
          <a-table-summary-cell align="center" v-show="getTableColumnsKeys.indexOf('customerNo') > -1"> / </a-table-summary-cell>
          <a-table-summary-cell align="right" v-show="getTableColumnsKeys.indexOf('cost_stand') > -1"> / </a-table-summary-cell>
          <a-table-summary-cell align="right" v-show="getTableColumnsKeys.indexOf('cost_current') > -1"> / </a-table-summary-cell>
          <a-table-summary-cell align="right" v-show="getTableColumnsKeys.indexOf('stock') > -1"> {{ handleNumber(getTableSum.stock || 0) }} </a-table-summary-cell>
          <a-table-summary-cell align="right" v-show="getTableColumnsKeys.indexOf('sold_num') > -1"> {{ handleNumber(getTableSum.sold_num || 0) }} </a-table-summary-cell>
          <a-table-summary-cell align="right" v-show="getTableColumnsKeys.indexOf('pass_per') > -1"> {{ getTableSum.pass_per || 0 }} </a-table-summary-cell>
          <a-table-summary-cell align="right" v-show="getTableColumnsKeys.indexOf('cost_sum') > -1"> {{ handleNumber(getTableSum.cost_sum || 0) }} </a-table-summary-cell>
          <a-table-summary-cell align="right" v-show="getTableColumnsKeys.indexOf('product_num_stand') > -1"> {{ handleNumber(getTableSum.product_num_stand || 0) }} </a-table-summary-cell>
          <a-table-summary-cell align="right" v-show="getTableColumnsKeys.indexOf('in_stock_plan') > -1"> {{ handleNumber(getTableSum.in_stock_plan || 0) }} </a-table-summary-cell>
          <a-table-summary-cell align="right" v-show="getTableColumnsKeys.indexOf('in_stock_actuality') > -1"> {{ handleNumber(getTableSum.in_stock_actuality || 0) }} </a-table-summary-cell>
          <a-table-summary-cell align="right" v-show="getTableColumnsKeys.indexOf('ac_per') > -1"> {{ getTableSum.ac_per || 0 }} </a-table-summary-cell>
          <a-table-summary-cell align="right" v-show="getTableColumnsKeys.indexOf('ac_per2') > -1"> {{ getTableSum.ac_per2 || 0 }} </a-table-summary-cell>
          <a-table-summary-cell align="right" v-show="getTableColumnsKeys.indexOf('ac_per3') > -1"> {{ getTableSum.ac_per3 || 0 }} </a-table-summary-cell>
        </a-table-summary-row>
      </a-table-summary>
    </template>
  </a-table>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { numberToFixed, roundToDotNumber, debounce, checkZero, tableSortFnc } from '@/utils/util'
import { ExclamationCircleOutlined } from '@ant-design/icons-vue'
import ToolsItemIntro from '@/components/ToolsItemIntro.vue'

export default {
  name: 'SolutionExtraPlan_QTLMX',
  components: {
    ExclamationCircleOutlined,
    ToolsItemIntro
  },
  props: {
    isMobile: {
      type: Boolean,
      default: false
    },
    numberFnc: {
      type: Function,
      default: null
    },
    hasActualityData: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      pagination: false,
      tableData: [],
      otherSortCal: {
        pass_per: ['sold_num', 'product_num'],
        ac_per: ['in_stock_plan', 'product_num_stand'],
        ac_per2: ['stock', 'in_stock_plan', 'sold_num'],
        ac_per3: ['in_stock_actuality', 'in_stock_plan']
      },
      getTableSum: {
        cost_stand: 0,
        cost_current: 0,
        stock: 0,
        sold_num: 0,
        product_num: 0,
        pass_per: 0,
        cost_sum: 0,
        product_num_stand: 0,
        in_stock_plan: 0,
        in_stock_actuality: 0,
        ac_per: 0,
        ac_per2: 0,
        ac_per3: 0
      }
    }
  },
  computed: {
    ...mapGetters([]),

    getTableColumns() {
      let columns = [
        { title: '产品编码ID', dataIndex: 'no', align: 'center', ...tableSortFnc('no', 'string'), minWidth: 600 },
        { title: '客户', dataIndex: 'customer', align: 'center', ...tableSortFnc('customer', 'string') },
        { title: '产品客户匹配码', dataIndex: 'customerNo', align: 'center', ...tableSortFnc('customer', 'string') },

        { title: '产品标准成本', dataIndex: 'cost_stand', align: 'right', ...tableSortFnc('cost_stand') },
        { title: '完工产品单位成本', dataIndex: 'cost_current', align: 'right', ...tableSortFnc('cost_current') },
        { title: '期初库存', dataIndex: 'stock', align: 'right', ...tableSortFnc('stock') },
        { title: '销量', dataIndex: 'sold_num', align: 'right', ...tableSortFnc('sold_num') },
        { title: '产品合格率', dataIndex: 'pass_per', align: 'right', ...tableSortFnc('pass_per') },

        // { title: '产品变动成本-（标准成本）', dataIndex: 'cost_stand', align: 'right' },
        // { title: '产品变动成本-（当期完工）', dataIndex: 'cost_current', align: 'right' },
        { title: '总投入', dataIndex: 'cost_sum', align: 'right', ...tableSortFnc('cost_sum') },
        { title: '标准入库量（含合格率）', helper: '', dataIndex: 'product_num_stand', align: 'right', ...tableSortFnc('product_num_stand') },
        { title: '计划入库量（含合格率）', helper: '', dataIndex: 'in_stock_plan', align: 'right', ...tableSortFnc('in_stock_plan') }
        // { title: '实际入库量', helper: '', dataIndex: 'in_stock_actuality', align: 'right', ...tableSortFnc('in_stock_actuality') },
        // { title: '资源利用率', helper: '', dataIndex: 'ac_per', align: 'right', ...tableSortFnc('ac_per') },
        // { title: '计划达成率', helper: '', dataIndex: 'ac_per2', align: 'right', ...tableSortFnc('ac_per2') },
        // { title: '生产达成率', helper: '', dataIndex: 'ac_per3', align: 'right' , ...tableSortFnc('ac_per3')},
      ]

      if (this.hasActualityData) {
        columns.push({ title: '实际入库量', helper: '', dataIndex: 'in_stock_actuality', align: 'right', ...tableSortFnc('in_stock_actuality') })
      }

      columns = columns.concat([
        { title: '资源利用率', helper: '', dataIndex: 'ac_per', align: 'right', ...tableSortFnc('ac_per') },
        { title: '计划达成率', helper: '', dataIndex: 'ac_per2', align: 'right', ...tableSortFnc('ac_per2') }
      ])
      if (this.hasActualityData) {
        columns.push({ title: '生产达成率', helper: '', dataIndex: 'ac_per3', align: 'right', ...tableSortFnc('ac_per3') })
      }
      if (this.isMobile) {
        columns = [
          { title: '产品编码ID', dataIndex: 'no', align: 'center', fixed: 'left', ...tableSortFnc('no') },
          { title: '资源利用率', helper: '', dataIndex: 'ac_per', align: 'right', ...tableSortFnc('ac_per') },
          { title: '计划达成率', helper: '', dataIndex: 'ac_per2', align: 'right', ...tableSortFnc('ac_per2') },
          { title: '生产达成率', helper: '', dataIndex: 'ac_per3', align: 'right', ...tableSortFnc('ac_per3') }
        ]
        if (this.hasActualityData) {
          columns.push({ title: '生产达成率', helper: '', dataIndex: 'ac_per3', align: 'right', ...tableSortFnc('ac_per3') })
        }
      }
      return columns
    },
    getTableColumnsKeys() {
      return this.getTableColumns.map((item) => {
        return item.dataIndex
      })
    }
  },
  mounted() {
    // this.createWatch_tableData()
  },
  methods: {
    handleNumber(number) {
      if (this.numberFnc) {
        return this.numberFnc(number)
      } else {
        return this.$dealThousands(number)
      }
    },
    createTableData(options, pageObj) {
      // const tableData = info[0]
      // const serialStandObj = info[1]||{}
      // const customerNoProductObj = info[2]||{}
      const { fncResult_stockAndYc, fncResult_serialStand, fncResult_SerialProduction } = pageObj.fncSettingOptions(
        {
          params_tax_per: 0,
          params_sold_per: 0,
          params_cost_per: 0,
          params_pass_per: 0
        },
        true
      )
      const tableData = fncResult_SerialProduction.newTableData || []
      const serialStandObj = fncResult_serialStand.colSpan || {}
      const customerNoProductObj = fncResult_SerialProduction.colSpan || {}

      let tableDataNew = []
      let sumObj = {
        cost_stand: 0,
        cost_current: 0,
        stock: 0,
        sold_num: 0,
        product_num: 0,
        pass_per: 0,
        cost_sum: 0,
        product_num_stand: 0,
        in_stock_plan: 0,
        in_stock_actuality: 0,
        ac_per: 0,
        ac_per2: 0,
        ac_per3: 0
      }

      tableData.map((item) => {
        const customerNo = item.customerNo
        const no = item.no
        const pass_per = item.pass_per

        const serialStandObjInfo = serialStandObj[no] || ''
        const customerNoProductObjInfo = customerNoProductObj[customerNo] || ''
        const stock = customerNoProductObjInfo.stock || '0'
        const sold_num = customerNoProductObjInfo.sold_num || '0'
        const cost_stand = serialStandObjInfo.total_stand_cost || '0'
        const cost_current = numberToFixed(!item.isFullSerial ? 0 : customerNoProductObjInfo.trans_in_money || '0')
        const cost_sum = numberToFixed(customerNoProductObjInfo.cost_sum || '0')
        const in_stock_actuality = customerNoProductObjInfo.serial_stand_product_num_stand_actuality_min
        const product_num_stand = numberToFixed(checkZero(cost_stand) ? 0 : (cost_sum / cost_stand) * (pass_per / 100))
        const in_stock_plan = customerNoProductObjInfo.stand_in_stock_div
        const product_num = sold_num / (pass_per / 100)

        if (!(customerNo in tableDataNew)) {
          const info = {
            no: item.no,
            customer: item.customer,
            customerNo: item.customerNo,
            pass_per: roundToDotNumber(item.pass_per) + '%',
            cost_stand: cost_stand,
            product_num: product_num,
            product_num_stand: product_num_stand,
            cost_current: cost_current,
            cost_sum: cost_sum,
            stock: stock,
            sold_num: sold_num,
            in_stock_plan: in_stock_plan,
            in_stock_actuality: in_stock_actuality,
            ac_per: roundToDotNumber(checkZero(product_num_stand) ? 0 : (in_stock_plan / product_num_stand) * 100) + '%',
            ac_per2: roundToDotNumber(checkZero(sold_num) ? 0 : ((stock * 1 + in_stock_plan * 1) / sold_num) * 100) + '%',
            ac_per3: roundToDotNumber(checkZero(in_stock_plan) ? 0 : (in_stock_actuality / in_stock_plan) * 100) + '%'
          }
          tableDataNew[customerNo] = info

          for (let key in sumObj) {
            if (key in this.otherSortCal) {
            } else {
              sumObj[key] = roundToDotNumber(sumObj[key] * 1 + info[key] * 1)
            }
          }
        }
      })

      for (let key in sumObj) {
        if (key in this.otherSortCal) {
          const arr = this.otherSortCal[key]
          if (arr.length == 3) {
            sumObj[key] = roundToDotNumber(checkZero(arr[2]) ? 0 : ((sumObj[arr[0]] * 1 + sumObj[arr[1]] * 1) / sumObj[arr[2]]) * 100) + '%'
          } else {
            sumObj[key] = roundToDotNumber(checkZero(arr[1]) ? 0 : (sumObj[arr[0]] / sumObj[arr[1]]) * 100) + '%'
          }
        } else {
        }
      }
      // console.log(sumObj)
      this.getTableSum = sumObj
      this.tableData = Object.values(tableDataNew)
      // console.log(tableDataNew)
    }
  }
}
</script>

<style>
</style>