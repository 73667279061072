<template>
  <div style="width: 100%; height: 100%">
    <a-spin :spinning="isMobile ? false : spinning">
      <div class="svp_echart" :class="isMobile ? 'svp_echart_mobile' : 'svp_echart_pc'">
        <a-row>
          <a-col :span="isMobile ? '24' : '14'">
            <div :class="isMobile ? 'isMobile' : 'isPC'">
              <SliderInputBlock v-model:value="xAxisNum" :min="0.1" :max="3" :step="0.1" />
            </div>
          </a-col>
          <a-col v-if="!isMobile" :span="isMobile ? '24' : '8'" style="text-align: right">
            <a-switch v-model:checked="isLegendShow" size="small" checked-children="图例" un-checked-children="图例" @change="changeLegendShow" />
          </a-col>
        </a-row>

        <div class="echart_box" :id="echartId" :style="isMobile ? 'height: 100%' : getBodyWidth"></div>
      </div>
      <div class="guide-children" v-if="showZeroPoint && !isMobile">
        <div class="head">盈亏平衡点</div>
        <div class="RichText">
          <div v-if="!zeroPintValues.length">在当前销售结构下,无盈亏平衡点</div>
          <template v-for="(item, index) in zeroPintValues" :key="index">
            <div>
              在当前销售结构下, 盈亏点销量：{{ $dealThousands(item.soldNum) }} 总收入：{{ $dealThousands(item.comeIn) }} 总成本：{{ $dealThousands(item.materialCost) }} 管理利润：{{
                $dealThousands(item.profitManager)
              }}
            </div>
          </template>
        </div>
      </div>
    </a-spin>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { mapGetters } from 'vuex'
import { numberToFixed, roundToDotNumber, checkZero, debounce, getMindValue, echartGraphic, bigNumberTransform, chartNowSize } from '@/utils/util'
import SliderInputBlock from '@/components/SliderInputBlock.vue'
import calMixIn from '../calMixIn.vue'
import { isMobile } from '@/utils/device'

//echart变量
const myChart = {}
//默认线条
const lineStyleDefault = { lineStyle: { width: 2 } }
//其它线条
const lineStyle = { lineStyle: { width: 1 } }

const defaultEchartData = (_isMobile) => {
  if (_isMobile) {
    lineStyleDefault.lineStyle.width = 1
  }
  return [
    //收入=0*加权平均单位不含税售价     1.7*加权平均单位不含税售价   3.4*加权平均单位不含税售价  5.1*加权平均单位不含税售价...
    { flagComplete: false, name: 'priceComeInPrice', data: { ...lineStyleDefault, markArea: {}, name: '总收入', type: 'line', stack: 'priceComeInPrice', data: [], showEchartlegend: true } },
    //总成本＝变动成本总额+固定成本总额 + 对应销量区间的混合成本总额
    { flagComplete: false, name: 'materialCostMixRagePrice', data: { ...lineStyleDefault, name: '总成本', type: 'line', stack: 'materialCostMixRagePrice', data: [], showEchartlegend: true } },
    //管理利润 = 总收入-变动成本总额-固定成本总额-对应销售区间混合成本总额
    { flagComplete: false, name: 'managerProfitMoeny', data: { ...lineStyleDefault, name: '管理利润', type: 'line', stack: 'managerProfitMoeny', data: [], showEchartlegend: true } },
    //预测X轴点 = 管理利润为0的点的销量
    { flagComplete: false, name: 'prediction', data: { ...lineStyleDefault, name: '预测', type: 'line', stack: 'prediction', data: [], showEchartlegend: true } },
    //变动成本总额＝产量*加权平均单位变动成本
    { flagComplete: false, name: 'materialCostPrice', data: { ...lineStyle, name: '变动成本', type: 'line', stack: 'materialCostPrice', data: [], showEchartlegend: false } },
    //总固定成本=固定成本总额
    { flagComplete: false, name: 'fixedMoeny', data: { ...lineStyle, name: '总固定成本', type: 'line', stack: 'fixedMoeny', data: [], showEchartlegend: false } },
    //加成后的总边际贡献 = 总收入-总变动成本
    { flagComplete: false, name: 'sideMoeny', data: { ...lineStyle, name: '总边际贡献', type: 'line', stack: 'sideMoeny', data: [], showEchartlegend: false } },
    //毛利润 = 总收入-变动成本总额-制造环节固定成本总额-制造环节对应销量所在区间的混合成本总额
    { flagComplete: false, name: 'profitMoeny', data: { ...lineStyle, name: '毛利润', type: 'line', stack: 'profitMoeny', data: [], showEchartlegend: false } }

    //这一条总保持再最后一条记录不然再画混合线条会出问题
    // 总混合成本=对应销量区间混合成本总额
    // { flagComplete: false, name: 'mixMoeny', data: { name: '总混合成本', type: 'line', stack: 'mixMoeny', data: [], showEchartlegend: true } }
  ]
}

export default {
  name: 'SvpLine',
  props: {
    isMobile: {
      type: Boolean,
      default: false
    },
    forceShowEchart: {
      type: Boolean,
      default: false
    },
    showZeroPoint: {
      type: Boolean,
      default: false
    },
    echartId: {
      type: String,
      required: true
    },
    excelData_yc: {
      type: Array,
      required: true
    },
    columns_step_mix: {
      type: Array,
      required: true
    },
    tableData_step_fixed: {
      type: Array,
      required: true
    },

    tableData_step_mix: {
      type: Array,
      required: true
    },
    isPrint: {
      type: Boolean,
      default: false
    },
    pagination: {
      type: [Boolean, Object],
      default: false
    },
    planType: {
      type: String,
      default: ''
    },
    isWatchXYChannel: {
      type: Boolean,
      default: false
    },
    svpYMaxValue: {
      type: Number,
      default: 0
    },
    svpXMap: {
      type: Array,
      default: () => []
    },
    numberFnc: {
      type: Function,
      default: null
    }
  },
  components: {
    SliderInputBlock
  },
  mixins: [calMixIn],
  data() {
    return {
      isLegendShow: false,
      sureDrawEchart: false,
      xAxisNum: 1,

      points: [],
      //x坐标轴
      xAxisData: [],

      echartData: [],
      echartDataIndex: {},
      //图例
      echartlegend: {},
      showEchartlegend: {},
      debounce: () => {},

      intro: [],
      //盈亏平衡点说明
      zeroPintValues: [],
      spinning: true
    }
  },
  computed: {
    ...mapGetters([
      'getHasToolAuth',
      //   'sum_sold_num',
      //   'sum_price_add_tax',
      //   'avg_price_no_tax_unit_money',
      //   'sum_fixed_total_money',
      //   'sum_pass_sold_num_price',
      //   'tableData_step_mix_field',
      //   'tableData_step_fixed_field',
      'mixRangeData'
      //   'sum_pass_per'
    ]),

    // tableData_step_mix() {
    //   return this.$store.state.tools.tableData_step_mix
    // },
    // tableData_step_fixed() {
    //   return this.$store.state.tools.tableData_step_fixed
    // },
    // svpYMaxValue() {
    //   return this.$store.state.tools.svpYMaxValue
    // },
    // svpXMap() {
    //   return this.$store.state.tools.svpXMap || []
    // },
    getTableData() {
      return this.handleData(this.excelData_yc, this.tableData_step_fixed, this.tableData_step_mix, this.columns_step_mix)
    },
    sum_sold_num() {
      return this.getTableData.sum_sold_num
    },
    sum_price_add_tax() {
      return this.getTableData.sum_price_add_tax
    },
    avg_price_no_tax_unit_money() {
      return this.getTableData.avg_price_no_tax_unit_money
    },
    sum_fixed_total_money() {
      return this.getTableData.sum_fixed_total_money
    },
    sum_pass_sold_num_price() {
      return this.getTableData.sum_pass_sold_num_price
    },
    sum_pass_per() {
      return this.getTableData.sum_pass_per
    },
    getBodyWidth() {
      return document.body.clientWidth < 1441 ? 'height:289px' : document.body.clientWidth > 1441 && document.body.clientWidth < 1919 ? 'height:352px' : 'height:436px'
    }
    // tableData_step_yc() {
    //   return this.$store.state.tools.tableData_step_yc
    // },

    // excelData_ycChange() {
    //   return this.$store.state.tools.excelData_ycChange
    // }
    //   columns_step_mix(){
    //     return this.$store.state.tools.columns_step_mix
    //   },
    //   tableData_step_fixed(){
    //     return this.$store.state.tools.tableData_step_fixed
    //   },
    //   tableData_step_mix(){
    //     return this.$store.state.tools.tableData_step_mix
    //   },
  },
  watch: {
    '$store.state.tools.spinning1': function (newValue, oldValue) {
      console.log('========newValue,oldValue', newValue, oldValue)
      this.spinning = newValue
    },
    //需要与加成后的方案对比x轴
    svpXMap: {
      deep: true,
      handler: function (value) {
        this.xAxisData = value
        //预测线条
        this.drawMarkLine(value, this.sum_sold_num)

        this.$nextTick(() => {
          //重新画图
          this.debounce()
        })
      }
    },
    planType(value) {
      if (value == 'base') {
        this.$nextTick(() => {
          setTimeout(() => {
            myChart[this.echartId].resize()
          }, 200)
        })
      }
    },

    // sum_sold_num(value) {
    //   //平分等分
    //   const xAxis_divide = 1000

    //   const xMaxNum = Math.ceil(value ?? 0)
    //   const step = xMaxNum / xAxis_divide
    //   const xAxisDataGroup = []
    //   for (let i = 0; i <= xMaxNum; i++) {
    //     xAxisDataGroup.push( numberToFixed( step * i * this.xAxisNum) )
    //   }

    //   this.xAxisData = xAxisDataGroup
    //   this.$nextTick(() => {
    //     //重新画图
    //     this.drawEchart()
    //   })
    // },

    echartData: {
      //重新画图
      deep: true,
      handler: function (value) {
        // let flagComplete = true
        // value.map(function (item) {
        //   if (!item.flagComplete) {
        //     flagComplete = false
        //   }
        // })
        // if (!flagComplete) {
        //   return
        // }
        // this.sureDrawEchart = true

        // console.log('>>>>>>>>>>>>>>>showEchartlegend<<<<<<<<<<<<<<<<')
        // console.log(this.showEchartlegend)
        this.$nextTick(() => {
          //重新画图
          this.debounce()
        })
      }
    },
    svpYMaxValue(value) {
      //重新画图
      this.debounce()
    }
  },
  mounted() {
    this.debounce = debounce(this.drawEchart)

    // this.createWatch_mixMoeny_line()

    // this.createWatch_calGroupFnc()

    // this.createWatch_priceComeInPrice()
    // this.createWatch_fixedMoeny()
    // this.createWatch_materialCostPrice()
    // this.createWatch_materialCostMixRagePrice()
    // this.createWatch_mixMoeny()
    // this.createWatch_sideMoeny()
    // this.createWatch_profitMoeny()
    // this.createWatch_managerProfitMoeny()

    var chartDom = document.getElementById(this.echartId)
    myChart[this.echartId] = echarts.init(chartDom, null, {
      renderer: 'canvas',
      useDirtyRect: false
    })

    this.$nextTick(() => {
      //重新画图
      ;[this.echartData, this.echartDataIndex] = this.handleDrawLine()

      this.$nextTick(() => {
        this.createWatch_sum_sold_num_and_xAxisNum()
        //处理临界点问题
        this.createWatch_calGroup()
        // this.createWatch_mixMoeny_line()
      })

      // this.debounce()
    })
  },
  methods: {
    //图例显示
    changeLegendShow(value) {
      this.isLegendShow = value
      var chartDom = document.getElementById(this.echartId)
      myChart[this.echartId] = echarts.init(chartDom, null, {
        renderer: 'canvas',
        useDirtyRect: false
      })
      var legendShow = (myChart[this.echartId].getOption().legend[0].show = this.isLegendShow)

      // 设置图例的显示状态
      myChart[this.echartId].setOption({
        legend: {
          show: legendShow
        }
      })
    },
    handleDrawLine() {
      const lines = []
      const lineIndex = {}
      let index = 0
      defaultEchartData(this.isMobile).map((item) => {
        // console.log('tools1_cvp_line_' + item.name,this.getHasToolAuth('tools1_cvp_line_' + item.name))
        // if (this.forceShowEchart || this.getHasToolAuth('tools1_cvp_line_' + item.name)) {
        if (this.getHasToolAuth('tools1_cvp_line_' + item.name)) {
          lines.push(item)
          lineIndex[item.name] = index
          index++
        }
      })
      return [lines, lineIndex]
    },
    drawSvp() {},

    // //计算盈亏平衡位置
    comeInPointIntro() {
      var excelData_yc = this.excelData_yc
      var columns_step_mix = this.columns_step_mix || []
      var tableData_step_fixed = this.tableData_step_fixed || []
      var tableData_step_mix = this.tableData_step_mix || []
      var tableDataMixMoney = tableData_step_mix[tableData_step_mix.length - 1] || {}
      const obj = this.handleData(excelData_yc, tableData_step_fixed, tableData_step_mix, columns_step_mix, {})
      // console.log(obj)

      //合格率
      const productPer = checkZero(obj.sum_pass_sold_num) ? 0 : obj.sum_sold_num / obj.sum_pass_sold_num
      // console.log('合格率：', productPer);

      //不含税单价
      const noTaxPrice = checkZero(obj.sum_sold_num) ? 0 : obj.sum_price_come_in / obj.sum_sold_num
      // console.log('不含税单价:', noTaxPrice);

      //成本单价
      const costMoney = checkZero(obj.sum_pass_sold_num) ? 0 : obj.sum_material_cost_money / obj.sum_pass_sold_num
      // const costMoney = this.sum_pass_sold_num_price
      // console.log('成本单价:', costMoney);

      //固定费用
      const fixMoney = obj.sum_fixed_total_money

      const valuePer = checkZero(productPer) ? 0 : costMoney / productPer
      // const soldNum = tableData_step_fixed *1 / ( noTaxPrice - valuePer );

      //销量*不含税单价 = 销量/合格率 * 成本单价 + 固定 + 对应销量混合范围的金额
      const soldNumFnc = (disNum) => {
        return checkZero(noTaxPrice - valuePer) ? 0 : (disNum * 1) / (noTaxPrice - valuePer)
      }
      //统计收入信息
      const staticsIn = (soldNum) => {
        //产量
        const productNum = checkZero(productPer) ? 0 : soldNum / productPer
        // console.log('>????',costMoney * productNum , fixMoney * 1 , this.tableData_step_mix_field('total', soldNum, this.tableData_step_mix, this.columns_step_mix) * 1)

        // console.log('计算成本',soldNum,productNum,costMoney)
        const comeIn = noTaxPrice * soldNum
        const materialCost = costMoney * productNum + fixMoney * 1 + this.tableData_step_mix_field('total', soldNum, this.tableData_step_mix, this.columns_step_mix) * 1
        return {
          //销量
          soldNum: roundToDotNumber(soldNum),
          //收入
          comeIn: roundToDotNumber(comeIn),
          //成本
          materialCost: roundToDotNumber(materialCost),
          //管理利润
          profitManager: roundToDotNumber(comeIn - materialCost)
        }
      }

      const values = []
      let soldNum = 0
      if (columns_step_mix && columns_step_mix.length > 0) {
        columns_step_mix.map(function (item, index) {
          if (index > 0) {
            const range_min = item.range_min || 0
            const range_max = item.range_max || 0

            const mixMoney = tableDataMixMoney[item.dataIndex]
            soldNum = soldNumFnc(mixMoney * 1 + fixMoney * 1)
            // console.log(mixMoney*1,fixMoney*1)
            // console.log("--------------------")
            // console.log("销量区间范围:",range_min,range_max)
            // console.log("盈亏平衡点销量:",soldNum)
            // console.log(range_min,range_max,soldNum,mixMoney,item.dataIndex)
            if (soldNum >= range_min && soldNum <= range_max) {
              values.push(staticsIn(soldNum))
            }
          }
        })
      } else {
        soldNum = soldNumFnc(fixMoney * 1)
        if (soldNum > 0) {
          values.push(staticsIn(soldNum))
        }
      }
      // console.log(">>>>>>>盈亏平衡点<<<<<<<<<")
      // console.log(columns_step_mix)
      // console.log(values)
      this.zeroPintValues = values
    },
    // createWatch_calGroupFnc(){
    //   this.$watch(
    //     () => [ this.tableData_step_yc,this.columns_step_mix,this.tableData_step_fixed,this.tableData_step_mix ],
    //     (info) => {
    //         var excelData_yc = info[0];
    //         var columns_step_mix = info[1];
    //         var tableData_step_fixed = info[2];
    //         var tableData_step_mix = info[3]||[];
    //         var tableDataMixMoney = tableData_step_mix[tableData_step_mix.length-1]||{}
    //         const obj = this.handleData(excelData_yc,tableData_step_fixed,tableData_step_mix,columns_step_mix,{})
    //         console.log(obj)

    //         //合格率
    //         const productPer = checkZero(obj.sum_pass_sold_num) ? 0 : obj.sum_sold_num/obj.sum_pass_sold_num;
    //         console.log('合格率：', productPer);

    //         //不含税单价
    //         const noTaxPrice = checkZero(obj.sum_sold_num) ? 0 : obj.sum_price_come_in / obj.sum_sold_num
    //         console.log('不含税单价:', noTaxPrice);

    //         //成本单价
    //         const costMoney = checkZero(obj.sum_pass_sold_num) ? 0 : obj.sum_material_cost_money / obj.sum_pass_sold_num
    //         console.log('成本单价:', costMoney);

    //         //固定费用
    //         const fixMoney = obj.sum_fixed_total_money;

    //         const valuePer = checkZero(productPer) ? 0 : costMoney/productPer;
    //         // const soldNum = tableData_step_fixed *1 / ( noTaxPrice - valuePer );

    //         //销量*不含税单价 = 销量/合格率 * 成本单价 + 固定 + 对应销量混合范围的金额
    //         const soldNumFnc = (disNum)=>{
    //           return checkZero(noTaxPrice - valuePer) ? 0 : (disNum *1 /( noTaxPrice - valuePer )) ;
    //         }

    //         const values = [];
    //         columns_step_mix.map(function(item,index){
    //           if(index>0){
    //             const range_min = item.range_min||0
    //             const range_max = item.range_max||0

    //             const mixMoney = tableDataMixMoney[item.dataIndex]
    //             const soldNum = soldNumFnc(mixMoney*1+fixMoney*1);
    //             // console.log(mixMoney*1,fixMoney*1)
    //             console.log("--------------------")
    //             console.log("销量区间范围:",range_min,range_max)
    //             console.log("盈亏平衡点销量:",soldNum)
    //             // console.log(range_min,range_max,soldNum,mixMoney,item.dataIndex)
    //             if(soldNum>=range_min && soldNum<=range_max){
    //               values.push(soldNum)
    //             }
    //           }

    //         })

    //         console.log(values)

    //     })
    // },

    //计算盈亏平衡位置
    createWatch_calGroup() {
      //总收入 = 不含税售价✖销量再合计所有的产品
      let timer = 0
      this.$watch(
        () => [
          this.xAxisData,
          this.avg_price_no_tax_unit_money,
          this.sum_pass_sold_num_price,
          this.sum_fixed_total_money,
          this.sum_pass_per,
          this.tableData_step_mix,
          this.columns_step_mix,
          this.mixRangeData
        ],
        (info) => {
          clearTimeout(timer)
          timer = setTimeout(() => {
            const xAxis = info[0]
            const value = info[1]

            const cost_value = info[2]
            const fixed_money = info[3]
            const per = info[4]

            const mixRangeData = info[7]
            //交点
            const crossData = []

            let [echartData, _] = this.handleDrawLine()

            let costInUp = null
            let hasCross = false
            let upItem = ''
            let hasCrossData = []
            //总成本
            const materialCostMixRagePriceData = []
            //变动成本数据
            const materialCostPriceData = []
            //总收入
            const comeInData = []
            //管理利润
            const profitManagerData = []
            //毛利润
            const profitMaoData = []
            //固定成本
            const fixedMoenyData = []
            //边际贡献
            const sideMoenyData = []

            //区域颜色处理
            const crossFncItem = (x, y, diff) => {
              var color = diff > 0 ? '#5470c633' : '#91cc7533'

              return { coord: [x, y * 1], itemStyle: { color: color } }
            }
            // console.log('-----',cost_value,per)
            // console.log('-----',cost_value,per)
            xAxis.map((item) => {
              //产量
              const num = checkZero(per) ? 0 : item / (per / 100)
              //收入
              const comeIn = numberToFixed(item * value)
              //成本
              const costIn = cost_value * num * 1 + fixed_money * 1 + this.tableData_step_mix_field('total', item, this.tableData_step_mix, this.columns_step_mix) * 1
              // console.log(item,cost_value * num * 1 , fixed_money * 1 , this.tableData_step_mix_field('total', item, this.tableData_step_mix, this.columns_step_mix))
              // console.log('成本',item,costIn,cost_value,num)
              const diff = comeIn - costIn
              //总成本
              materialCostMixRagePriceData.push(numberToFixed(costIn))
              //总收入
              comeInData.push(numberToFixed(comeIn))
              //变动成本数据
              materialCostPriceData.push(numberToFixed(cost_value * 1 * num))
              //管理利润
              profitManagerData.push(
                numberToFixed(item * value - (cost_value * num * 1 + fixed_money * 1 + this.tableData_step_mix_field('total', item, this.tableData_step_mix, this.columns_step_mix) * 1))
              )

              //毛利润
              profitMaoData.push(
                numberToFixed(
                  item * value -
                    (cost_value * num * 1 +
                      this.tableData_step_fixed_field('zhizao_sum', this.tableData_step_fixed) * 1 +
                      this.tableData_step_mix_field('zhizao_sum', item, this.tableData_step_mix, this.columns_step_mix) * 1)
                )
              )

              //固定成本
              fixedMoenyData.push(numberToFixed(fixed_money))

              //边际贡献
              sideMoenyData.push(numberToFixed(item * value - num * cost_value))

              if (costInUp !== null) {
                if (diff == 0 || (diff < 0 && costInUp > 0) || (diff > 0 && costInUp < 0)) {
                  hasCross = true
                  crossData.push({
                    symbolSize: 25,
                    itemStyle: { color: '#ff0000' },
                    name: '交点',
                    value: '',
                    xAxis: item,
                    yAxis: comeIn,
                    sold_num: item,
                    product_num: num,
                    comeIn: comeIn,
                    costIn: costIn,
                    in: comeIn - costIn
                  })
                }

                if (hasCross) {
                  hasCrossData.push([
                    crossFncItem(item, comeIn * 1, diff),
                    crossFncItem(upItem, costIn * 1, diff)
                    // { coord: [item, comeIn * 1], itemStyle: { color: crossColor } },
                    // { coord: [upItem, costIn * 1], itemStyle: { color: crossColor } }
                  ])
                } else {
                  hasCrossData.push([crossFncItem(item, comeIn * 1, diff), crossFncItem(upItem, costIn * 1, diff)])
                }
              }
              upItem = item
              costInUp = diff
            })
            // console.log(this.checkEchartDataIndex('priceComeInPrice'))
            // console.log(data)
            // console.log(hasCrossData)
            const priceComeInPriceIndex = this.checkEchartDataIndex('priceComeInPrice')
            if (priceComeInPriceIndex > -1) {
              echartData[priceComeInPriceIndex].data.markArea = {
                data: hasCrossData
              }
              echartData[priceComeInPriceIndex].data.markPoint = {
                data: crossData // [{ name: '周最低', value: -2, xAxis: 1, yAxis: -1.5 }]
              }

              //总收入 数据
              echartData[this.checkEchartDataIndex('priceComeInPrice')].data.data = comeInData
              echartData[this.checkEchartDataIndex('priceComeInPrice')].flagComplete = true
            }

            //  总成本 数据
            const materialCostMixRagePriceIndex = this.checkEchartDataIndex('materialCostMixRagePrice')
            if (materialCostMixRagePriceIndex > -1) {
              echartData[materialCostMixRagePriceIndex].data.data = materialCostMixRagePriceData
              echartData[materialCostMixRagePriceIndex].flagComplete = true
            }

            // console.log(this.checkEchartDataIndex('materialCostPrice'))
            const materialCostPriceIndex = this.checkEchartDataIndex('materialCostPrice')
            if (materialCostPriceIndex > -1) {
              //  变动成本数据 数据
              echartData[this.checkEchartDataIndex('materialCostPrice')].data.data = materialCostPriceData
              echartData[this.checkEchartDataIndex('materialCostPrice')].flagComplete = true
            }

            const managerProfitMoenyIndex = this.checkEchartDataIndex('managerProfitMoeny')
            if (managerProfitMoenyIndex > -1) {
              //管理利润 数据
              echartData[managerProfitMoenyIndex].data.data = profitManagerData
              echartData[managerProfitMoenyIndex].flagComplete = true
            }

            const profitMoenyIndex = this.checkEchartDataIndex('profitMoeny')
            if (profitMoenyIndex > -1) {
              //毛利润 数据
              echartData[profitMoenyIndex].data.data = profitMaoData
              echartData[profitMoenyIndex].flagComplete = true
            }

            const fixedMoenyIndex = this.checkEchartDataIndex('fixedMoeny')
            if (fixedMoenyIndex > -1) {
              //固定成本数据
              echartData[fixedMoenyIndex].data.data = fixedMoenyData
              echartData[fixedMoenyIndex].flagComplete = true
            }

            const sideMoenyIndex = this.checkEchartDataIndex('sideMoeny')
            if (sideMoenyIndex > -1) {
              //边际收益
              echartData[sideMoenyIndex].data.data = sideMoenyData
              echartData[sideMoenyIndex].flagComplete = true
            }

            if (this.getHasToolAuth('tools1_cvp_line_mixMoeny')) {
              // 混合成本
              const data = [{ flagComplete: false, name: 'mixMoeny', data: { ...lineStyle, name: '总混合成本', type: 'line', stack: 'mixMoeny', data: [], showEchartlegend: false } }]
              const data2 = []
              xAxis.map((item) => {
                data2.push(numberToFixed(this.tableData_step_mix_field('total', item, this.tableData_step_mix, this.columns_step_mix) * 1))
              })
              mixRangeData.map((item, index) => {
                const min = item.min * 1
                const max = item.max * 1
                const name = '( ' + min + '~' + max + ' )'
                const { letMin, letMax } = getMindValue(xAxis, min, max)

                data.push({
                  name: '混合成本' + name,
                  flagComplete: true,
                  data: {
                    ...lineStyle,
                    name: '混合成本' + name,
                    showEchartlegend: false,
                    type: 'line',
                    stack: 'mixMoeny' + index,
                    data: [
                      [letMin, item.money * 1],
                      [letMax, item.money * 1]
                    ]
                  }
                })
              })

              if (data.length > 1) {
                data[0].flagComplete = true
                data[0].data.data = data2
                echartData = echartData.concat(data)
              }
            }

            this.echartData = echartData
            this.drawMarkLine(xAxis, this.sum_sold_num)

            // //交点线条
            //盈亏点说明
            this.comeInPointIntro()
          }, 2000)
        }
      )
    },

    createWatch_sum_sold_num_and_xAxisNum() {
      this.$watch(
        () => [this.sum_sold_num, this.xAxisNum],
        (info) => {
          const value = info[0]
          const xAxisNum = info[1] * 1
          //平分等分
          const xAxis_divide = 1000

          const xMaxNum = Math.ceil(value ?? 0)
          const step = xMaxNum / xAxis_divide
          const xAxisDataGroup = []
          const len = 1500
          for (let i = 0; i <= len; i++) {
            xAxisDataGroup.push(numberToFixed(step * i * xAxisNum))
          }

          this.xAxisData = xAxisDataGroup
          //预测线条
          // this.drawMarkLine(xAxisDataGroup, value)
          // this.$nextTick(() => {
          //   //重新画图
          //   this.debounce()
          // })
        }
      )
    },
    drawMarkLine(xAxis, value) {
      const obj = getMindValue(xAxis, 0, value)
      const letMax = obj.letMax
      const predictionIndex = this.checkEchartDataIndex('prediction')
      console.log('predictionIndex', predictionIndex)
      if (predictionIndex > -1) {
        this.echartData[predictionIndex].flagComplete = true
        this.echartData[predictionIndex].data = {
          name: '预测',
          showEchartlegend: true,
          type: 'line',
          markLine: {
            ...lineStyleDefault,
            yAxisIndex: 0,
            precision: 0, //精度
            symbol: 'none', //去掉箭头
            data: [{ name: '预测', xAxis: letMax }],
            label: {
              // 显示标签
              show: true,
              // 设置字体样式
              textStyle: {
                // 设置字体大小
                fontSize: this.isMobile ? 10 : chartNowSize(12)
              },
              formatter: function (param) {
                return Number(param.value).toFixed(0) // 设置精度为两位小数
              }
            }
          }
        }
      }
    },
    // createWatch_prediction() {
    //   this.$watch(
    //     () => [this.xAxisData, this.sum_sold_num],
    //     (info) => {
    //       const xAxis = info[0]
    //       const value = info[1]
    //       const obj = getMindValue(xAxis, 0, value)
    //       const letMax = obj.letMax

    //       if(this.checkEchartDataIndex('prediction')>-1){
    //         this.echartData[this.checkEchartDataIndex('prediction')].flagComplete = true
    //         this.echartData[this.checkEchartDataIndex('prediction')].data = {
    //           name: '预测',
    //           showEchartlegend: true,
    //           type: 'line',
    //           markLine: {
    //             ...lineStyleDefault,
    //             yAxisIndex: 0,
    //             symbol: 'none', //去掉箭头
    //             data: [{ name: '预测', xAxis: letMax }]
    //           }
    //         }
    //       }

    //     }
    //   )
    // },

    // createWatch_mixMoeny_line() {
    //   this.$watch(
    //     () => [this.xAxisData, this.mixRangeData],
    //     (info) => {

    //       if(!this.getHasToolAuth('tools1_cvp_line_mixMoeny')){
    //         return
    //       }

    //       const xAxis = info[0]
    //       const value = info[1]

    //       const data = [
    //         { flagComplete: false, name: 'mixMoeny', data: { ...lineStyle, name: '总混合成本', type: 'line', stack: 'mixMoeny',data: [], showEchartlegend: false } }
    //       ]
    //       const data2 = []
    //       xAxis.map((item) => {
    //         data2.push(numberToFixed(this.tableData_step_mix_field('total', item, this.tableData_step_mix, this.columns_step_mix) * 1))
    //       })
    //       value.map((item, index) => {
    //         const min = item.min * 1
    //         const max = item.max * 1
    //         const name = '( ' + min + '~' + max + ' )'
    //         const { letMin, letMax } = getMindValue(xAxis, min, max)

    //         data.push({
    //           name: '混合成本' + name,
    //           flagComplete: true,
    //           data: {
    //             ...lineStyle,
    //             name: '混合成本' + name,
    //             showEchartlegend: false,
    //             type: 'line',
    //             stack: 'mixMoeny' + index,
    //             data: [
    //               [letMin, item.money * 1],
    //               [letMax, item.money * 1]
    //             ]
    //           }
    //         })
    //       })
    //       // const index = this.checkEchartDataIndex('mixMoeny');
    //       // const spliceLen = this.echartData.length-(index+1);
    //       // console.log('>>>>>>>> index <<<<<<<<<<<')
    //       // console.log(index)
    //       // console.log(spliceLen)
    //       // if(spliceLen>0){
    //       // this.echartData.splice(index,spliceLen)
    //       // }
    //       if (data.length > 1) {
    //         data[0].flagComplete = true
    //         data[0].data.data = data2
    //         const echartData = this.handleDrawLine().concat(data)
    //         console.log(this.echartData,data)
    //         // echartData[this.checkEchartDataIndex('mixMoeny')].flagComplete = true
    //         // echartData[this.checkEchartDataIndex('mixMoeny')].data.data = data2
    //         this.echartData = echartData
    //       }

    //       // this.echartData[this.checkEchartDataIndex('mixMoeny')].flagComplete = true
    //       // this.echartData[this.checkEchartDataIndex('mixMoeny')].data.data = data2
    //     }
    //   )
    // },
    checkEchartDataIndex(name) {
      return name in this.echartDataIndex ? this.echartDataIndex[name] : -1
      let index = -1
      for (let i = 0; this.echartData.length; i++) {
        const info = this.echartData[i]
        if (info && info.name === name) {
          index = i
          break
        }
      }
      return index
    },
    drawEchart() {
      if (Object.keys(this.echartlegend).length != this.echartData.length) {
        const echartlegend = {}
        this.echartData.map(function (item) {
          const data = item.data || {}
          const name = data.name
          echartlegend[name] = !!data.showEchartlegend
        })
        this.echartlegend = echartlegend
        this.showEchartlegend = echartlegend
      } else {
        this.showEchartlegend = {}
      }
      this._drawEchart()
    },
    _drawEchart() {
      const seriesData = this.echartData.map((item) => item.data)
      //获取Y轴最大的值
      var seriesMaxValue = 0
      seriesData.map(function (item) {
        for (let i = 0; i < (item.data || []).length; i++) {
          const value = item.data[i]
          if (!isNaN(value) && value * 1 > seriesMaxValue * 1) {
            seriesMaxValue = value * 1
          }
        }
      })

      if (this.isWatchXYChannel) {
        //查看最后一个元素的值对比

        const len_svp = this.svpXMap.length
        const len = this.xAxisData.length
        if (len > 0 && (len_svp != len || this.svpXMap[len_svp - 1] != this.xAxisData[len])) {
          this.$store.commit('updateToolsState', { field: 'svpXMap', value: this.xAxisData })
        }

        this.$store.commit('updateToolsState', { field: 'svpYMaxValue', value: seriesMaxValue })
      }
      // console.log('----------------------------------')
      // console.log(this.echartData.map((item) => item.data.name))

      const _isMobile = this.isMobile
      const that = this
      const option = {
        title: {
          text: ''
        },
        tooltip: {
          trigger: 'axis',
          textStyle: {
            fontSize: this.isMobile ? 10 : chartNowSize(12) // 设置字体大小为14
          },
          formatter: function (params, ticket, callback) {
            const soldNum = (params[0] || {}).name
            let str = '销量：' + (that.numberFnc ? that.numberFnc(soldNum, 2, true) : numberToFixed(soldNum, 2)) + '<br />'
            params.map(function (item, index) {
              let value = item.value
              if (value instanceof Array) {
                value = value[1] || 0
              }
              str += item.marker + item.seriesName + ' : ' + (that.numberFnc ? that.numberFnc(value) : numberToFixed(value, 2)) + '<br />'
            })
            return str
          }
        },

        legend: _isMobile
          ? {
              data: this.echartData.map((item) => item.data.name),
              selected: this.showEchartlegend,
              orient: 'vertical',
              top: '0',
              left: '40',
              height: '120',
              itemHeight: 5,
              itemGap: 5,
              // backgroundColor: '#fff',
              textStyle: {
                lineHeight: 1,
                fontSize: 10
              }
              // itemStyle: {
              //   // opacity:0,
              // },
              // lineStyle: {
              //   width: 1,
              //   inactiveWidth: 1
              // }
            }
          : {
              show: this.isLegendShow,
              data: this.echartData.map((item) => item.data.name),
              selected: this.showEchartlegend,
              orient: 'vertical',
              top: '20',
              left: document.body.clientWidth < 1441 ? '40' : document.body.clientWidth > 1441 && document.body.clientWidth < 1919 ? '50' : '60',
              padding: 0,
              height: document.body.clientWidth > 1919 ? '140' : '120',
              width: 300,
              type: 'scroll',
              itemHeight: 5,
              itemGrap: 0,
              itemGap: 2,
              pageIconSize: 7, //翻页按钮大小
              pageTextStyle: {
                fontSize: 9
              },
              // backgroundColor: '#ffffff',
              textStyle: {
                lineHeight: 1,
                fontSize: chartNowSize(12)
              }
            },
        grid: _isMobile
          ? {
              top: '35',
              left: '0',
              right: '35',
              bottom: '3%',
              containLabel: true
            }
          : {
              top: '32',
              left: '0',
              right: '13%',
              bottom: '3%',
              containLabel: true
            },
        toolbox: this.isMobile
          ? {}
          : {
              top: '20',
              itemSize: chartNowSize(12),
              feature: {
                saveAsImage: {}
              }
            },
        xAxis: {
          type: 'category',
          name: '销量',
          nameTextStyle: {
            fontSize: this.isMobile ? 10 : chartNowSize(12)
          },
          boundaryGap: false,
          data: this.xAxisData,
          axisLabel: {
            textStyle: {
              fontSize: this.isMobile ? 10 : chartNowSize(12) // 字体大小
            },
            formatter: function (value, index) {
              // 在这里对x轴的数值进行转换处理
              if (that.numberFnc) {
                return parseInt(value, 0)
              } else {
                return numberToFixed(value, 0) //销量
              }
            }
          }
        },
        yAxis: {
          type: 'value',
          name: seriesMaxValue > 10000 ? '万元' : '元',
          nameTextStyle: {
            padding: [0, 30, 0, 0],
            fontSize: this.isMobile ? 10 : chartNowSize(12)
          },
          max: this.svpYMaxValue > 0 ? this.svpYMaxValue : seriesMaxValue,
          axisLabel: {
            textStyle: {
              fontSize: this.isMobile ? 10 : chartNowSize(12) // 字体大小
            },
            formatter: function (value) {
              // 在这里对y轴的数值进行转换处理
              // console.log('=============================value', value)
              // console.log('=============================that.numberFnc', that.numberFnc)
              if (that.numberFnc) {
                // return Math.round(that.numberFnc(value, 0)) > 10000 ? Math.round(that.numberFnc(value, 0)) / 10000 : Math.round(that.numberFnc(value, 0))
                return Math.round(value > 10000 || value < -10000 ? value / 10000 : value)
              } else {
                return Math.round(value > 10000 || value < -10000 ? value / 10000 : value)
              }
            }
          }
        },
        graphic: echartGraphic(_isMobile),
        series: this.echartData.map((item) => item.data)
      }
      // console.log(this.xAxisData)
      // console.log(this.echartData.map((item) => item.data))
      // 释放资源
      myChart[this.echartId].setOption(option)
    },
    //输出图片
    generateImage() {
      return myChart[this.echartId].getDataURL('png')
    }
  }
}
</script>

<style lang="scss" scoped>
.ant-spin-nested-loading {
  height: 100%;
}
:deep(.ant-spin-container) {
  height: 100%;
}
</style>
