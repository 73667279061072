<template>
  <div style="width: 100%; height: 100%">
    <div class="svp_echart" :class="isMobile ? 'svp_echart_mobile' : 'svp_echart_pc'">
      <a-row>
        <a-col :span="isMobile ? '24' : '14'">
          <div :class="isMobile ? 'isMobile' : 'isPC'">
            <SliderInputBlock v-model:value="xAxisNum" :min="0.1" :max="3" :step="0.1" />
          </div>
        </a-col>
        <a-col v-if="!isMobile" :span="isMobile ? '24' : '8'" style="text-align: right">
          <a-switch v-model:checked="isLegendShow" size="small" checked-children="图例" un-checked-children="图例" @change="changeLegendShow" />
        </a-col>
      </a-row>
      <div class="echart_box" :id="echartId" :style="isMobile ? 'height: 100%' : getBodyWidth"></div>
    </div>
    <div class="guide-children" v-if="showZeroPoint && !isMobile">
      <div class="head">盈亏平衡点</div>
      <div class="RichText" style="width: 99%">
        <div v-if="!zeroPintValues.length">在当前销售结构下,无盈亏平衡点</div>
        <template v-for="(item, index) in zeroPintValues" :key="index">
          <div>
            <div>在当前销售结构下，盈亏平衡点在销量{{ $dealThousands(item.sold_num) }}附近；</div>
            <div>
              当销量为{{ $dealThousands(item.sold_num) }}时，总收入：{{ $dealThousands(item.comeIn) }} ；总成本：{{ $dealThousands(item.costIn) }}；管理利润：{{ $dealThousands(item.profitManager) }}
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { mapGetters } from 'vuex'
import { numberToFixed, roundToDotNumber, checkZero, debounce, getMindValue, echartGraphic, bigNumberTransform, chartNowSize } from '@/utils/util'
import SliderInputBlock from '@/components/SliderInputBlock.vue'
import { echartXPointsFnc } from '@/utils/UtilToos2P'
//echart变量
const myChart = {}
//默认线条
const lineStyleDefault = { lineStyle: { width: 2 } }
//其它线条
const lineStyle = { lineStyle: { width: 1 } }

export default {
  name: 'SvpLine',
  components: {
    SliderInputBlock
  },
  props: {
    isMobile: {
      type: Boolean,
      default: false
    },
    forceShowEchart: {
      type: Boolean,
      default: false
    },
    showZeroPoint: {
      type: Boolean,
      default: false
    },
    echartId: {
      type: String,
      required: true
    },
    isWatchXYChannel: {
      type: Boolean,
      default: false
    },

    moduleName: {
      type: String,
      required: true
    },
    planType: {
      type: String,
      default: ''
    },
    numberFnc: {
      type: Function,
      default: null
    }
  },
  data() {
    return {
      isLegendShow: false,
      xAxisNum: 1,
      xAxisNumTimer: 0,
      debounce: () => {},

      seriesData: [],
      seriesDataIndex: {},

      up_XPoints: [],
      XPoints: [],

      drawLinesFnc: (params) => {},
      zeroPintValues: [],

      //图例
      echartlegend: {},
      showEchartlegend: {}
    }
  },
  computed: {
    getBodyWidth() {
      return document.body.clientWidth < 1441 ? 'height:289px' : document.body.clientWidth > 1441 && document.body.clientWidth < 1919 ? 'height:352px' : 'height:436px'
    }
  },
  watch: {
    planType(value) {
      if (value == 'base') {
        this.$nextTick(() => {
          setTimeout(() => {
            myChart[this.echartId].resize()
          }, 200)
        })
      }
    },
    xAxisNum(value) {
      clearTimeout(this.xAxisNumTimer)
      this.xAxisNumTimer = setTimeout(() => {
        this.drawLinesFnc({ xAxisNum: value })
      }, 300)
    },

    seriesData: {
      deep: true,
      handler: function (value) {
        this.$nextTick(() => {
          //重新画图
          this.debounce()
        })
      }
    }
  },
  mounted() {
    this.debounce = debounce(this.drawEchart)

    var chartDom = document.getElementById(this.echartId)
    myChart[this.echartId] = echarts.init(chartDom, null, {
      renderer: 'canvas',
      useDirtyRect: false
    })
    this.$nextTick(() => {
      ;[this.seriesData, this.seriesDataIndex] = this.getDefaultDrawLine()

      this.$nextTick(() => {})
    })
  },

  methods: {
    //图例显示
    changeLegendShow(value) {
      this.isLegendShow = value
      var chartDom = document.getElementById(this.echartId)
      myChart[this.echartId] = echarts.init(chartDom, null, {
        renderer: 'canvas',
        useDirtyRect: false
      })
      var legendShow = (myChart[this.echartId].getOption().legend[0].show = this.isLegendShow)
      // 设置图例的显示状态
      myChart[this.echartId].setOption({
        legend: {
          show: legendShow
        }
      })
    },
    // //计算盈亏平衡位置
    // comeInPointIntro() {
    //   var excelData_yc = this.excelData_yc

    //   this.zeroPintValues = values
    // },
    getDefaultDrawLine() {
      if (this.isMobile) {
        lineStyleDefault.lineStyle.width = 1
      }
      const lines = [
        //收入=0*加权平均单位不含税售价     1.7*加权平均单位不含税售价   3.4*加权平均单位不含税售价  5.1*加权平均单位不含税售价...
        { ...lineStyleDefault, name: '总收入', type: 'line', stack: 'priceComeInPrice', data: [], markArea: {}, markPoint: [], showEchartlegend: true },
        //总成本＝变动成本总额+固定成本总额 + 对应销量区间的混合成本总额
        { ...lineStyleDefault, name: '总成本', type: 'line', stack: 'materialCostMixRagePrice', data: [], showEchartlegend: true },
        //管理利润 = 总收入-变动成本总额-固定成本总额-对应销售区间混合成本总额
        { ...lineStyleDefault, name: '管理利润', type: 'line', stack: 'managerProfitMoeny', data: [], showEchartlegend: true },
        //预测X轴点 = 管理利润为0的点的销量
        { ...lineStyleDefault, name: '预测', type: 'line', stack: 'prediction', markLine: {}, showEchartlegend: true },

        //变动成本总额
        { ...lineStyleDefault, name: '变动成本', type: 'line', stack: 'materialCostPrice', data: [], showEchartlegend: false },
        //总固定成本=固定成本总额
        { ...lineStyle, name: '固定成本', type: 'line', stack: 'fixedMoeny', data: [], showEchartlegend: false },
        //加成后的总边际贡献 = 总收入-总变动成本
        { ...lineStyle, name: '边际贡献', type: 'line', stack: 'sideMoeny', data: [], showEchartlegend: false },

        //毛利润 = 总收入-变动成本总额-制造环节固定成本总额-制造环节对应销量所在区间的混合成本总额
        { ...lineStyle, name: '毛利润', type: 'line', stack: 'profitMoeny', data: [], showEchartlegend: false }
      ]
      const linesIndex = {}
      lines.map((item, index) => (linesIndex[item.stack] = index))
      return [lines, linesIndex]
    },

    drawLines(options) {
      this.drawLinesFnc = (params = {}, XPoints = null) => {
        const value = options.tableList || []
        //总销量
        const soldNum = options.soldNum || 0
        //合格率
        const avg_pass_per_product = options.avg_pass_per_product || 0
        //不含税单价
        const avg_price_no_tax_unit_money = options.avg_price_no_tax_unit_money || 0
        //固定
        const fixedMoney = options.fixedMoney || 0
        //成本中心否的费用
        const fixedNoMoney = options.fixedNoMoney || 0
        //中间表
        const colSpan = options.colSpan || {}

        const fncColSpanPrice = (num) => options.fncColSpanPrice(value, num, colSpan, this.moduleName)
        //差异函数

        //x轴 点位
        this.XPoints = XPoints || echartXPointsFnc(soldNum, params.xAxisNum || 1)

        //收入线条
        this.drawPriceComeInPriceLine(this.XPoints, avg_price_no_tax_unit_money, fixedMoney, fncColSpanPrice)

        //总成本
        // this.drawMaterialCostMixRagePrice(this.XPoints,fixedMoney,fncColSpanPrice)

        //变动成本线条
        this.drawMaterialCostPriceLine(this.XPoints, fncColSpanPrice)

        //边际收益
        this.drawSideMoenyLine(this.XPoints, avg_price_no_tax_unit_money, fncColSpanPrice)
        //管理利润
        this.drawManagerProfitMoenyLine(this.XPoints, avg_price_no_tax_unit_money, fixedMoney, fncColSpanPrice)
        //毛利
        this.drawProfitMoenyLine(this.XPoints, avg_price_no_tax_unit_money, fixedNoMoney, fncColSpanPrice)

        // 画预测线条
        this.drawPredictionLine(soldNum)

        //画固定
        this.drawFixedLine(this.XPoints, fixedMoney)
      }

      this.$nextTick(() => {
        this.drawLinesFnc({ xAxisNum: this.xAxisNum })
        const legendNames = {}
        const defaultShowNames = ['总收入', '总成本', '管理利润', '预测']
        this.seriesData.map((item) => {
          legendNames[item.name] = defaultShowNames.indexOf(item.name) > -1
        })
        this.echartSettingOptions({
          legend: this.isMobile
            ? {
                data: Object.keys(legendNames),
                selected: legendNames,
                orient: 'vertical',
                // align: 'left',
                top: '0',
                left: '40',
                height: '140',
                itemHeight: 5,
                itemGap: 5,
                // backgroundColor: '#ffffff',
                textStyle: {
                  lineHeight: 1,
                  fontSize: 10
                },
                lineStyle: {
                  width: 1,
                  inactiveWidth: 1
                }
              }
            : {
                data: Object.keys(legendNames),
                selected: legendNames,
                orient: 'vertical',
                align: 'left',
                padding: 10,
                top: '25',
                left: '115',
                backgroundColor: '#ffffff'
              }
        })
      })
    },

    drawPriceComeInPriceLine(xAxis, value, fixed_money, fncColSpanPrice) {
      //总收入 = 不含税售价✖销量再合计所有的产品
      const data = []
      const dataCost = []

      let costInUp = null
      let hasCross = false
      let upItem = ''
      let hasCrossData = []
      //交点
      const crossData = []
      //区域颜色处理
      const crossFncItem = (x, y, diff) => {
        var color = diff > 0 ? '#5470c633' : '#91cc7533'

        return { coord: [x, y * 1], itemStyle: { color: color } }
      }
      xAxis.map((item) => {
        const comeIn = roundToDotNumber(item * value)
        const product_num = item.product_num
        const costIn = roundToDotNumber(fixed_money * 1 + fncColSpanPrice(item))
        const diff = roundToDotNumber(comeIn - costIn)
        data.push(comeIn)

        dataCost.push(roundToDotNumber(costIn))

        if (costInUp !== null) {
          if (parseFloat(item) > 0 && (diff == 0 || (diff < 0 && costInUp > 0) || (diff > 0 && costInUp < 0))) {
            hasCross = true
            crossData.push({
              symbolSize: 25,
              itemStyle: { color: '#ff0000' },
              name: '交点',
              value: '',
              xAxis: item,
              yAxis: comeIn,
              sold_num: item,
              product_num: product_num,
              comeIn: comeIn,
              costIn: costIn,
              profitManager: roundToDotNumber(comeIn - costIn),
              in: diff
            })
          }

          if (hasCross) {
            hasCrossData.push([
              crossFncItem(item, comeIn * 1, diff),
              crossFncItem(upItem, costIn * 1, diff)
              // { coord: [item, comeIn * 1], itemStyle: { color: crossColor } },
              // { coord: [upItem, costIn * 1], itemStyle: { color: crossColor } }
            ])
          } else {
            hasCrossData.push([crossFncItem(item, comeIn * 1, diff), crossFncItem(upItem, costIn * 1, diff)])
          }
        }
        upItem = item
        costInUp = diff
      })
      const priceComeInPriceIndex = this.seriesDataIndex['priceComeInPrice']
      this.seriesData[priceComeInPriceIndex].data = data
      this.seriesData[priceComeInPriceIndex].markArea = {
        data: hasCrossData
      }
      this.seriesData[priceComeInPriceIndex].markPoint = {
        data: crossData // [{ name: '周最低', value: -2, xAxis: 1, yAxis: -1.5 }]
      }

      const materialCostMixRagePriceIndex = this.seriesDataIndex['materialCostMixRagePrice']
      this.seriesData[materialCostMixRagePriceIndex].data = dataCost
      // if(!this.zeroPintValues.length){
      this.zeroPintValues = crossData
      this.$emit('changeData', { zeroPintValues: crossData })
      // }
    },

    // drawMaterialCostMixRagePrice(xAxis,fixed_money,fncColSpanPrice) {
    //   //总成本 = 固定+成本
    //     const data = []
    //     xAxis.map( (item) => {
    //         data.push(numberToFixed(fixed_money +  fncColSpanPrice( item ) ))
    //     })
    //     const materialCostMixRagePriceIndex = this.seriesDataIndex['materialCostMixRagePrice'];
    //     this.seriesData[materialCostMixRagePriceIndex].data = data
    // },

    //预测线条
    drawPredictionLine(value) {
      const obj = getMindValue(this.XPoints, 0, value)
      const letMax = obj.letMax
      const predictionIndex = this.seriesDataIndex['prediction']
      this.seriesData[predictionIndex].markLine = {
        ...lineStyleDefault,
        yAxisIndex: 0,
        symbol: 'none', //去掉箭头
        data: [{ name: '预测', xAxis: letMax }],
        label: {
          // 显示标签
          show: true,
          // 设置字体样式
          textStyle: {
            // 设置字体大小
            fontSize: this.isMobile ? 10 : chartNowSize(12)
          },
          formatter: function (param) {
            return Number(param.value).toFixed(0) // 设置精度为两位小数
          }
        }
      }
    },
    //固定
    drawFixedLine(xAxis, value) {
      const data = []
      xAxis.map((item) => {
        data.push(numberToFixed(value * 1))
      })
      const fixedMoenyIndex = this.seriesDataIndex['fixedMoeny']
      this.seriesData[fixedMoenyIndex].data = data
    },
    //变动
    drawMaterialCostPriceLine(xAxis, fncColSpanPrice) {
      const data = []
      xAxis.map((item) => {
        data.push(numberToFixed(fncColSpanPrice(item) * 1))
      })
      const materialCostPriceIndex = this.seriesDataIndex['materialCostPrice']
      this.seriesData[materialCostPriceIndex].data = data
    },
    //边际收益
    drawSideMoenyLine(xAxis, value, fncColSpanPrice) {
      const data = []
      xAxis.map((item) => {
        data.push(numberToFixed(item * value - fncColSpanPrice(item) * 1))
      })
      const sideMoenyIndex = this.seriesDataIndex['sideMoeny']
      this.seriesData[sideMoenyIndex].data = data
    },
    //管理利润
    drawManagerProfitMoenyLine(xAxis, value, fixedMoeny, fncColSpanPrice) {
      const data = []
      xAxis.map((item) => {
        data.push(numberToFixed(item * value - fixedMoeny - fncColSpanPrice(item) * 1))
      })
      const managerProfitMoenyIndex = this.seriesDataIndex['managerProfitMoeny']
      this.seriesData[managerProfitMoenyIndex].data = data
    },
    //毛利
    drawProfitMoenyLine(xAxis, value, fixedMoeny, fncColSpanPrice) {
      const data = []
      xAxis.map((item) => {
        data.push(numberToFixed(item * value - fixedMoeny - fncColSpanPrice(item) * 1))
      })
      const profitMoenyIndex = this.seriesDataIndex['profitMoeny']
      this.seriesData[profitMoenyIndex].data = data
    },
    echartSettingOptions(option) {
      myChart[this.echartId].setOption(option)
    },
    resetEchartInfo({ points, yMax }) {
      this.drawLinesFnc({}, points)
      myChart[this.echartId].setOption({
        max: yMax
      })
    },
    drawEchart() {
      if (Object.keys(this.echartlegend).length != this.seriesData.length) {
        const echartlegend = {}
        this.seriesData.map(function (item) {
          // const data = item.data || {}
          // const name = data.name
          const data = item
          const name = item.name
          echartlegend[name] = !!data.showEchartlegend
        })
        this.echartlegend = echartlegend
        this.showEchartlegend = echartlegend
      } else {
        this.showEchartlegend = {}
      }
      this._drawEchart()
    },
    _drawEchart() {
      const seriesData = this.seriesData
      //获取Y轴最大的值
      var seriesMaxValue = 0
      seriesData.map(function (item) {
        for (let i = 0; i < (item.data || []).length; i++) {
          const value = item.data[i]
          if (!isNaN(value) && value * 1 > seriesMaxValue * 1) {
            seriesMaxValue = value * 1
          }
        }
      })

      // if(this.isWatchXYChannel){
      //   //查看最后一个元素的值对比

      const len_up = this.up_XPoints.length
      const len = this.XPoints.length
      if (len > 0 && (len_up != len || this.up_XPoints[len_up - 1] != this.XPoints[len - 1])) {
        this.$emit('xAxisPointChange', this.XPoints, seriesMaxValue)
      }

      // }
      const _isMobile = this.isMobile
      const that = this

      const option = {
        title: {
          text: ''
        },
        tooltip: {
          trigger: 'axis',
          textStyle: {
            fontSize: this.isMobile ? 10 : chartNowSize(12) // 设置字体大小为14
          },
          formatter: function (params, ticket, callback) {
            const soldNum = (params[0] || {}).name
            let str = '销量：' + (that.numberFnc ? that.numberFnc(soldNum, 2, true) : soldNum) + '<br />'
            params.map(function (item, index) {
              let value = item.value
              if (value instanceof Array) {
                value = value[1] || 0
              }
              str += item.marker + item.seriesName + ' : ' + (that.numberFnc ? that.numberFnc(value) : value) + '<br />'
            })
            return str
          }
        },
        legend: _isMobile
          ? {
              data: this.seriesData.map((item) => item.name),
              selected: this.showEchartlegend,
              orient: 'vertical',
              top: '0',
              left: '40',
              height: '120',
              itemHeight: 5,
              itemGap: 5,
              // backgroundColor: '#ffffff',
              textStyle: {
                lineHeight: 1,
                fontSize: 10
              }
            }
          : {
              show: this.isLegendShow,
              data: this.seriesData.map((item) => item.name),
              selected: this.showEchartlegend,
              orient: 'vertical',
              top: '20',
              left: document.body.clientWidth < 1441 ? '40' : document.body.clientWidth > 1441 && document.body.clientWidth < 1919 ? '50' : '60',
              padding: 0,
              height: document.body.clientWidth > 1919 ? '140' : '110',
              width: 300,
              itemHeight: 5,
              itemGrap: 0,
              itemGap: 2,
              // backgroundColor: '#ffffff',
              textStyle: {
                lineHeight: 1,
                fontSize: this.isMobile ? 10 : chartNowSize(12)
              }
            },
        grid: {
          top: '32',
          left: '10',
          right: '13%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: this.isMobile
          ? {}
          : {
              top: '20',
              itemSize: chartNowSize(12),
              feature: {
                saveAsImage: {}
              }
            },
        xAxis: {
          type: 'category',
          name: '销量',
          nameTextStyle: {
            fontSize: this.isMobile ? 10 : chartNowSize(12)
          },
          boundaryGap: false,
          data: this.XPoints,
          axisLabel: {
            textStyle: {
              fontSize: this.isMobile ? 10 : chartNowSize(12) // 字体大小
            },
            formatter: function (value, index) {
              // 在这里对y轴的数值进行转换处理
              if (that.numberFnc) {
                return parseInt(value, 0)
              } else {
                return numberToFixed(value, 0)
              }
            }
          }
        },
        yAxis: {
          type: 'value',
          name: seriesMaxValue > 10000 ? '万元' : '元',
          nameTextStyle: {
            padding: [0, 30, 0, 0],
            fontSize: this.isMobile ? 10 : chartNowSize(12)
          },
          axisLabel: {
            textStyle: {
              fontSize: this.isMobile ? 10 : chartNowSize(12) // 字体大小
            },
            formatter: function (value) {
              // 在这里对y轴的数值进行转换处理
              if (that.numberFnc) {
                // return Math.round(that.numberFnc(value, 0)) > 10000 ? Math.round(that.numberFnc(value, 0)) / 10000 : Math.round(that.numberFnc(value, 0))
                return Math.round(value > 10000 || value < -10000 ? value / 10000 : value)
              } else {
                return Math.round(value > 10000 || value < -10000 ? value / 10000 : value)
              }
            }
          }
        },
        graphic: echartGraphic(_isMobile),
        series: seriesData
      }
      // 释放资源
      myChart[this.echartId].setOption(option)
    },
    //输出图片
    generateImage() {
      return myChart[this.echartId].getDataURL('png')
    }
  }
}
</script>

<style lang="scss">
.isMobile {
  .ant-slider {
    margin: 5px;
  }
  .ant-slider-horizontal .ant-slider-rail,
  .ant-slider-horizontal .ant-slider-track,
  .ant-slider-horizontal .ant-slider-step {
    height: 2px;
  }

  .ant-input-number {
    height: 20px;
  }
  .ant-input-number .ant-input-number-input {
    height: 20px;
  }
}
</style>
