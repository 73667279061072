<template>
  <!-- <a-modal title="参数配置" width="90%" style="top: 20px" v-model:open="visible" :confirmLoading="loading" @ok="handleSubmit" :footer="null" @cancel="close"> -->
  <div class="card-table setting_mobile">
    <div class="head">
      <div class="card-title">参数配置</div>

      <span :class="tableOpen ? 'hide' : 'show'" @click="tableOpen = !tableOpen">
        {{ tableOpen ? '收起' : '展开' }}
        <svg class="icon svg-icon" aria-hidden="true">
          <use xlink:href="#icon-xiangxia"></use>
        </svg>
      </span>
    </div>
    <div v-show="tableOpen">
      <a-form ref="formAuth" :rules="rules" :model="form" layout="vertical" :colon="false">
        <a-row :gutter="10">
          <a-col :span="24">
            <a-form-item label="客户" name="customer">
              <a-select placeholder="请选择" v-model:value="form.customer" allowClear show-search @change="handleSelectChange('customer')">
                <a-select-option v-for="(plan, index) in customer" :key="index" :value="plan">{{ plan }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="产品线" name="line">
              <a-select placeholder="请选择" v-model:value="form.line" allowClear show-search @change="handleSelectChange('line')">
                <a-select-option v-for="(plan, index) in line" :key="index" :value="plan">{{ plan }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="产品类别" name="type">
              <a-select placeholder="请选择" v-model:value="form.type" allowClear show-search @change="handleSelectChange('type')">
                <a-select-option v-for="(plan, index) in type" :key="index" :value="plan">{{ plan }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="产品编码" name="no">
              <a-select placeholder="请选择" v-model:value="form.no" allowClear show-search @change="handleSelectChange('group')">
                <a-select-option v-for="(plan, index) in no" :key="index" :value="plan">{{ plan }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :span="8">
            <a-form-item label="业务组" name="group">
              <a-select placeholder="请选择" v-model:value="form.group" allowClear show-search @change="handleSelectChange('group')">
                <a-select-option v-for="(plan, index) in group" :key="index" :value="plan">{{ plan }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="区域" name="area">
              <a-select placeholder="请选择" v-model:value="form.area" allowClear show-search @change="handleSelectChange('area')">
                <a-select-option v-for="(plan, index) in area" :key="index" :value="plan">{{ plan }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :span="8">
            <a-form-item label=" ">
              <div class="btn-group">
                <a-button type="primary" @click="handleAdd">添加</a-button>
                <a-button @click="handleReset" style="margin-left: -15px">重置</a-button>
              </div>
            </a-form-item>
            <!-- <div class="btn-group">
              <a-button type="primary" @click="handleAdd" style="margin-right: -20px">添加</a-button>
              <a-button @click="handleReset">重置</a-button>
            </div> -->
          </a-col>
        </a-row>
      </a-form>
      <div class="card-table-box">
        <a-table :pagination="false" :columns="columns" :data-source="tableData" :scroll="{ x: 550 }">
          <template #bodyCell="{ column, text, record, index }">
            <template v-if="column.dataIndex === 'type'">
              {{ groupType[record.type] }}
            </template>
            <template v-if="column.dataIndex === 'side_money_diff'">
              {{ numberFnc(text) }}
            </template>
            <template v-if="column.dataIndex === 'operate'">
              <a-button type="text" danger @click="handleDel(index)">删除</a-button>
            </template>
            <template v-if="column.dataIndex === 'price_per'">
              <!-- <SliderInputBlock v-model:value="record.price_per" :min="-100" :max="100" @change="handleChangeData" /> -->
              <a-input-number v-model:value="record.price_per" :min="-100" :max="100" @change="handleChangeData" />
            </template>
            <template v-if="column.dataIndex === 'sold_num_per'">
              <!-- <SliderInputBlock v-model:value="record.sold_num_per" :min="-100" :max="100" @change="handleChangeData" /> -->
              <a-input-number v-model:value="record.sold_num_per" :min="-100" :max="100" @change="handleChangeData" />
            </template>
            <template v-if="column.dataIndex === 'material_cost_per'">
              <!-- <SliderInputBlock v-model:value="record.material_cost_per" :min="-100" :max="100" @change="handleChangeData" /> -->
              <a-input-number v-model:value="record.material_cost_per" :min="-100" :max="100" @change="handleChangeData" />
            </template>
            <template v-if="column.dataIndex === 'pass_per'">
              <!-- <SliderInputBlock v-model:value="record.pass_per" :min="-100" :max="100" @change="handleChangeData" /> -->
              <a-input-number v-model:value="record.pass_per" :min="-100" :max="100" @change="handleChangeData" />
            </template>
            <template v-if="column.dataIndex === 'cost_money'">
              <a-input-number v-model:value="record.cost_money" @change="handleChangeData" />
            </template>
            <template v-if="column.dataIndex === 'cost_year_per'">
              <!-- <SliderInputBlock v-model:value="record.cost_year_per" :min="0" :max="100" @change="handleChangeData" /> -->
              <a-input-number v-model:value="record.cost_year_per" :min="-100" :max="100" @change="handleChangeData" />
            </template>
            <template v-if="column.dataIndex === 'cost_day'">
              <!-- <SliderInputBlock v-model:value="record.cost_day" :min="-365" :max="365" @change="handleChangeData" /> -->
              <a-input-number v-model:value="record.cost_day" :min="-365" :max="365" @change="handleChangeData" />
            </template>
          </template>
        </a-table>
      </div>

      <div class="card-table-desc">
        <div style="overflow-x: scroll; display: flex; white-space: nowrap; margin-top: 20px">
          <div v-for="(item, index) in tableData" :key="index" style="margin-right: 10px">
            <a-descriptions :title="item.groupName" :column="1" size="small" bordered :labelStyle="{ width: '150px', minWidth: '150px' }">
              <a-descriptions-item label="变动前不含税销售收入">
                <div class="descriptions-span">
                  {{ numberFnc(item.price_come_in) }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="变动后不含税销售收入">
                <div class="descriptions-span">
                  {{ numberFnc(item.price_come_in_after) }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="变动前含税销售收入">
                <div class="descriptions-span">
                  {{ numberFnc(item.sold_come_in) }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="变动后含税销售收入">
                <div class="descriptions-span">
                  {{ numberFnc(item.sold_come_in_after) }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="变动前边际收益">
                <div class="descriptions-span">
                  {{ numberFnc(item.side_money) }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="变动后边际收益">
                <div class="descriptions-span">
                  {{ numberFnc(item.side_money_after) }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="边际收益差异">
                <div class="descriptions-span">
                  {{ numberFnc(item.side_money_diff) }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="该次策略组合收益">
                <div class="descriptions-span">
                  {{ numberFnc(item.group_money) }}
                </div>
              </a-descriptions-item>
            </a-descriptions>
          </div>
        </div>
        <a-row :gutter="20">
          <a-col :span="12">
            <div class="descriptions-list">
              <div class="descriptions-header">策略组合</div>
              <div class="descriptions-item">
                <div class="descriptions-item-label">总边际收益</div>
                <div class="descriptions-item-span">{{ numberFnc(totalSideMoney) }}</div>
              </div>
              <div class="descriptions-item">
                <!-- 策略组合 -->
                <div class="descriptions-item-label">总管理利润</div>
                <div class="descriptions-item-span">{{ numberFnc(managerRate) }}</div>
              </div>
            </div>
          </a-col>
          <a-col :span="12">
            <div class="descriptions-list">
              <div class="descriptions-header">结果</div>
              <div class="descriptions-item">
                <div class="descriptions-item-label">最终收益</div>
                <div class="descriptions-item-span">{{ numberFnc(getMoney) }}</div>
              </div>
              <div class="descriptions-item">
                <div class="descriptions-item-label">最终利润</div>
                <div class="descriptions-item-span">{{ numberFnc(rateMoney) }}</div>
              </div>
            </div>
          </a-col>
        </a-row>
      </div>
    </div>
  </div>
</template>

<script>
import { message } from 'ant-design-vue'
import SliderInputBlock from '@/components/SliderInputBlock.vue'
import calMixIn from '../calMixIn.vue'
export default {
  name: 'SettingParams',
  props: {
    id: {
      type: [String, Number],
      default: 0
    },
    title: {
      type: String,
      default: '方案名称'
    },
    excelData_yc: {
      type: Array,
      default: () => []
    },
    tableData_step_fixed: {
      type: Array,
      default: () => []
    },
    tableData_step_mix: {
      type: Array,
      default: () => []
    },
    columns_step_mix: {
      type: Array,
      default: () => []
    },
    settingParams_tableData: {
      type: Array,
      default: () => []
    },
    numberFnc: {
      type: Function,
      default: null
    }
  },
  components: {
    SliderInputBlock
  },
  mixins: [calMixIn],
  data() {
    return {
      initDataState: false,

      tableOpen: false,
      visible: false,
      loading: false,
      form: {
        line: null,
        type: null,
        customer: null,
        area: null,
        group: null,
        no: null
      },
      params: {
        money: 0,
        per: 0,
        day: 0
      },
      limitTableItem: 2, //限制添加行数
      totalSideMoney: 0,
      managerRate: 0,
      getMoney: 0,
      rateMoney: 0,
      callback: null,
      rules: {},
      groupType: { line: '产品线', type: '产品类别', customer: '客户', area: '区域', group: '业务组', no: '产品编码' },

      line: [],
      type: [],
      customer: [],
      area: [],
      group: [],
      no: [],
      groupNoData: [],

      columns: [
        { title: '内容', dataIndex: 'groupName', width: 100, fixed: 'left' },
        { title: '含税价格(%)', dataIndex: 'price_per', width: 100 },
        { title: '销量(%)', dataIndex: 'sold_num_per', width: 100 },
        { title: '单位变动成本(%)', dataIndex: 'material_cost_per', width: 120 },
        { title: '合格率(%)', dataIndex: 'pass_per', width: 100 },
        { title: '资金成本', dataIndex: 'cost_money', width: 100 },
        { title: '年利率(%)', dataIndex: 'cost_year_per', width: 100 },
        { title: '天数', dataIndex: 'cost_day', width: 100 },
        { title: '边际差异', dataIndex: 'side_money_diff', width: 100 },

        { title: '操作', dataIndex: 'operate', width: 80 }
      ],
      tableData: [],

      calResultTimer: 0
    }
  },

  computed: {
    excelData_ycChange() {
      const data = this.excelData_yc || []
      return data
    }
    // tableData_step_fixed() {
    //   return this.$store.state.tools.tableData_step_fixed
    // },
    // tableData_step_mix() {
    //   return this.$store.state.tools.tableData_step_mix
    // },
    // columns_step_mix() {
    //   return this.$store.state.tools.columns_step_mix
    // }
  },
  watch: {
    settingParams_tableData: {
      handler(value) {
        this.setParams(value)
      }
    },
    excelData_ycChange: {
      immediate: true, //首次加载的时候执行函数
      handler(value) {
        this.handleSelectChange()
        this.handleCal()
        // if(this.initDataState){
        //   this.initDataState = false;
        //   this.handleChangeData()
        // }
        // value.map((item)=>{
        //     Object.keys(this.groupType).map((typeKey)=>{
        //         if(item[typeKey] && this[typeKey].indexOf(item[typeKey])===-1 ){
        //             this[typeKey].push(item[typeKey])
        //         }
        //     })
        // })
      }
    }
    // tableData:{
    //     deep:true,
    //     handler(value){
    //         clearTimeout(this.calResultTimer)
    //         this.calResultTimer = setTimeout(()=>{
    //             console.log('>>>>>>>>>>>> watch tableData handler')
    //             this.handleCal()
    //         },200)
    //     }
    // }
  },

  methods: {
    setParams(tableData) {
      this.tableData = tableData || []
      //计算数据
      // if (this.tableData.length) {
      //   this.initDataState = !this.excelData_ycChange.length;
      //   if(!this.initDataState){
      //     this.handleChangeData()
      //   }
      // }
    },
    handleReset() {
      this.$refs.formAuth.resetFields()
      this.$nextTick(() => {
        this.handleSelectChange()
      })
    },
    handleSelectChange() {
      Object.keys(this.groupType).map((typeKey) => {
        this[typeKey] = []
      })

      this.excelData_ycChange.map((item) => {
        const state = Object.keys(this.groupType).every((typeKey) => {
          return item[typeKey] && (!this.form[typeKey] || item[typeKey] == this.form[typeKey])
        })
        Object.keys(this.groupType).map((typeKey) => {
          if (item[typeKey] && this[typeKey].indexOf(item[typeKey]) === -1) {
            if (state) {
              this[typeKey].push(item[typeKey])
            }
          }
        })
      })
    },
    open() {
      this.visible = true
    },
    close() {
      this.visible = false
    },
    handleAdd() {
      if (this.tableData.length >= this.limitTableItem) {
        return message.error(`至多不得超过${this.limitTableItem}组合`)
      }
      const group = {}
      if (this.form.line) group.line = this.form.line
      if (this.form.type) group.type = this.form.type
      if (this.form.customer) group.customer = this.form.customer
      if (this.form.area) group.area = this.form.area
      if (this.form.group) group.group = this.form.group
      if (this.form.no) group.no = this.form.no
      if (!Object.keys(group).length) {
        return message.error('请选择项')
      }
      this.tableData.push({
        group: group,
        groupName: Object.values(group).join(' '),
        price_per: 0,
        sold_num_per: 0,
        pass_per: 0,
        cost_money: 0,
        cost_year_per: 0,
        cost_day: 0,
        material_cost_per: 0,
        //不含税销售收入
        price_come_in: 0,
        //后不含税销售收入
        price_come_in_after: 0,
        //前含税销售收入
        sold_come_in: 0,
        //后含税销售收入
        sold_come_in_after: 0,
        //边际贡献
        side_money: 0,
        //加成后边际贡献
        side_money_after: 0,
        //该次策略组合收益
        group_money: 0,
        //总边际收益
        totalSideMoney: 0,
        //机会成本
        cal_cost_money: 0,
        //边际差异
        side_money_diff: 0
      })

      //计算数据
      this.handleChangeData()
    },
    handleDel(index) {
      this.tableData.splice(index, 1)
      //计算数据
      this.handleChangeData()
    },
    handleSubmit() {},

    //组合数据-可能存在重复的数据产品编码问题
    handleCalBefore() {
      const excelData_ycChange = JSON.parse(JSON.stringify(this.excelData_ycChange))
      this.tableData.map((tableItem) => {
        excelData_ycChange.map((originItem) => {
          const dataItem = JSON.parse(JSON.stringify(originItem))
          const isEq = Object.keys(tableItem.group).every((groupKey) => {
            return dataItem[groupKey] === tableItem.group[groupKey]
          })
          if (isEq) {
            if (!('settingParam' in originItem)) {
              originItem.settingParam = {
                price_per: 0,
                sold_num_per: 0,
                material_cost_per: 0,
                pass_per: 0
              }
            }
            originItem.settingParam.price_per += tableItem.price_per * 1
            originItem.settingParam.sold_num_per += tableItem.sold_num_per * 1
            originItem.settingParam.material_cost_per += tableItem.material_cost_per * 1
            originItem.settingParam.pass_per += tableItem.pass_per * 1
          }
        })
      })
      return excelData_ycChange
    },
    handleTableItemData(excelData_ycChange) {
      //初始化数据
      this.tableData.map((tableItem) => {
        tableItem.price_come_in = 0
        tableItem.price_come_in_after = 0
        tableItem.sold_come_in = 0
        tableItem.sold_come_in_after = 0
        tableItem.side_money = 0
        tableItem.side_money_after = 0
        //机会成本
        tableItem.cal_cost_money = 0
        tableItem.side_money_diff = 0
      })

      //策略组合总边际收益
      this.tableData.map((tableItem) => {
        excelData_ycChange.map((originItem) => {
          //总变动成本
          originItem.material_cost_money = originItem.material_cost * originItem.pass_sold_num
          originItem.slid_money = originItem.price_come_in - originItem.material_cost_money

          const dataItem = JSON.parse(JSON.stringify(originItem))
          let side_money = dataItem.slid_money * 1

          if ('settingParam' in originItem) {
            // console.log('----settingParam:side_money',originItem,side_money)
            //前不含税销售收入
            tableItem.price_come_in += dataItem.price_come_in * 1
            //前含税销售收入
            tableItem.sold_come_in += dataItem.sold_come_in * 1
            //边际贡献
            tableItem.side_money += side_money
            //计算加成后
            //含税价格
            const price_per = tableItem.price_per * 1
            dataItem.price_add_tax = dataItem.price_add_tax * (1 + price_per / 100)
            //不含税价格
            if (price_per) {
              dataItem.price = dataItem.price_add_tax / (1 + dataItem.tax_per / 100)
            }
            //销量
            const sold_num_per = tableItem.sold_num_per * 1
            dataItem.sold_num = dataItem.sold_num * (1 + sold_num_per / 100)

            //后含税销售收入
            tableItem.sold_come_in_after += dataItem.price_add_tax * dataItem.sold_num
            //后不含税销售收入
            tableItem.price_come_in_after += dataItem.price * dataItem.sold_num

            //合格率
            const pass_per = tableItem.pass_per * 1
            dataItem.pass_per = dataItem.pass_per * (1 + pass_per / 100)
            //产量
            dataItem.pass_sold_num = !dataItem.pass_per ? 0 : dataItem.sold_num / (dataItem.pass_per / 100)

            //单位变动成本
            const material_cost_per = tableItem.material_cost_per * 1
            dataItem.material_cost = dataItem.material_cost * (1 + material_cost_per / 100)

            //销售收入
            dataItem.price_come_in = dataItem.price * dataItem.sold_num
            //总变动成本
            dataItem.material_cost_money = dataItem.material_cost * dataItem.pass_sold_num

            //边际贡献 = 销售收入 - 总变动成本
            side_money = dataItem.price_come_in - dataItem.material_cost_money
            tableItem.side_money_after += side_money

            tableItem.side_money_diff = tableItem.side_money_after - tableItem.side_money
          }
        })
        //机会成本
        const cost_money = (tableItem.cost_money || 0) * 1
        const cost_year_per = (tableItem.cost_year_per || 0) * 1
        const cost_day = (tableItem.cost_day || 0) * 1
        const calCostMoney = (cost_money * (cost_day / 365) * cost_year_per) / 100
        tableItem.calCostMoney = calCostMoney

        // 边际收益-机会成本=该次策略组合收益
        const groupMoney = tableItem.side_money_after - calCostMoney
        tableItem.group_money = groupMoney
      })
    },
    handleGroupData(excelData_ycChange) {
      //策略组合总边际收益
      let totalSideMoney = 0
      let totalGroupMoney = 0
      let totalCalCostMoney = 0
      let totalSideMoneyAfter = 0
      let totalSideMoneyOrigin = 0

      excelData_ycChange.map((dataItem) => {
        // const dataItem = JSON.parse(JSON.stringify(originItem))
        //总变动成本
        dataItem.material_cost_money = dataItem.material_cost * dataItem.pass_sold_num
        dataItem.side_money = dataItem.price_come_in - dataItem.material_cost_money
        //总变动成本
        let side_money = dataItem.side_money || 0 * 1
        if ('settingParam' in dataItem) {
          const price_per = dataItem.settingParam.price_per || 0
          const sold_num_per = dataItem.settingParam.sold_num_per || 0
          const material_cost_per = dataItem.settingParam.material_cost_per || 0
          const pass_per = dataItem.settingParam.pass_per || 0

          //计算加成后
          //含税价格
          dataItem.price_add_tax = dataItem.price_add_tax * (1 + price_per / 100)
          //不含税价格
          if (price_per) {
            dataItem.price = dataItem.price_add_tax / (1 + dataItem.tax_per / 100)
          }
          //销量
          dataItem.sold_num = dataItem.sold_num * (1 + sold_num_per / 100)
          //合格率
          dataItem.pass_per = dataItem.pass_per * (1 + pass_per / 100)
          //产量
          dataItem.pass_sold_num = !dataItem.pass_per ? 0 : dataItem.sold_num / (dataItem.pass_per / 100)

          //单位变动成本
          dataItem.material_cost = dataItem.material_cost * (1 + material_cost_per / 100)

          //销售收入
          dataItem.price_come_in = dataItem.price * dataItem.sold_num
          //总变动成本
          dataItem.material_cost_money = dataItem.material_cost * dataItem.pass_sold_num

          // console.log('----settingParam:side_money',dataItem,dataItem.price , dataItem.sold_num, dataItem.price_come_in , dataItem.material_cost_money)
          //边际贡献 = 销售收入 - 总变动成本
          side_money = dataItem.price_come_in - dataItem.material_cost_money
          totalSideMoneyAfter += side_money
        } else {
          totalSideMoneyOrigin += side_money
        }
        totalSideMoney += side_money
      })

      this.tableData.map((tableItem) => {
        totalGroupMoney += tableItem.group_money * 1
        totalCalCostMoney += tableItem.calCostMoney * 1
      })
      // console.log(totalSideMoney)
      // console.log(totalGroupMoney)
      // console.log(totalSideMoneyOrigin)
      // console.log(totalGroupMoney + totalSideMoneyOrigin)
      // console.log(totalCalCostMoney)
      if (!this.tableData.length) {
        this.totalSideMoney = 0
        this.managerRate = 0
        this.getMoney = 0
        this.rateMoney = 0
      } else {
        //计算收益
        const calData = this.handleData(excelData_ycChange, this.tableData_step_fixed, this.tableData_step_mix, this.columns_step_mix)
        //策略组合总边际收益  （A客户，B大类）的边际收益+（C客户，D区域）的边际收益+其他不在此范围内的原方案加成后的边际收益=策略组合总边际收益
        //总边界收益
        this.totalSideMoney = totalSideMoney
        //策略组合总边际收益-固定成本-混合成本=策略组合总管理利润
        this.managerRate = totalSideMoney - calData.sum_fixed_total_money - calData.sum_mix_total_money

        //最终收益 =（A客户，B大类）的边际收益-机会成本+（C客户，D区域）的边际收益-机会成本+其他不在此范围内的原方案加成后的边际收益
        const getMoney = totalSideMoney - totalCalCostMoney
        this.getMoney = getMoney
        //最终利润 = 最终收益-固定成本-混合成本
        this.rateMoney = getMoney - calData.sum_fixed_total_money - calData.sum_mix_total_money
      }
    },
    handleChangeData() {
      clearTimeout(this.calResultTimer)
      this.calResultTimer = setTimeout(() => {
        this.$emit('paramsChange', this.tableData)
        this.handleCal()
      }, 200)
    },
    handleCal() {
      //处理加成前的数据
      const excelData_ycChange = this.handleCalBefore()
      //处理单项数据
      this.handleTableItemData(excelData_ycChange)

      //处理策略组合数据
      this.handleGroupData(excelData_ycChange)
    }
  }
}
</script>

<style lang="less">
.setting_mobile {
  .head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0px !important;
    .card-title {
      margin-bottom: 0 !important;
    }
    span {
      color: #1e6dff;
      display: flex;
      align-items: center;
      font-size: 12px;
      .icon {
        width: 12px;
        height: 12px;
        font-size: 12px;
        fill: currentColor;
      }
      &.show {
        .icon {
          transform: rotate(180deg);
        }
      }
    }
  }
  .ant-form {
    font-size: 12px;
    .ant-form-item {
      margin-bottom: 5px;
      .ant-form-item-label {
        padding-bottom: 0px;
        > label {
          font-size: 12px;
        }
      }
      .ant-select .ant-select-selector .ant-select-selection-item,
      .ant-select .ant-select-selector .ant-select-selection-search-input {
        font-size: 12px !important;
      }
    }
    .btn-group {
      display: flex;
      justify-content: center;
      float: left;
      // justify-content: flex-end;
      gap: 20px;
      .ant-btn {
        width: 50px;
        font-size: 12px;
        height: 26px;
      }
    }
  }
  &.card-table {
    .card-table-box {
      .ant-table-wrapper {
        .ant-table {
          font-size: 12px;
        }
        .ant-table-cell {
          &.ant-table-cell-with-append {
            display: flex;
            align-items: center;
          }
          .ant-table-row-indent {
            display: none;
          }
          .ant-table-row-expand-icon-spaced {
            display: none;
          }
          .ant-table-row-indent + .ant-table-row-expand-icon {
            margin-top: 0px;
          }
        }
        .ant-table-thead > tr > th {
          padding: 4px 6px;
        }

        .ant-table.ant-table-small tfoot > tr > td,
        .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td,
        .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td {
          padding: 4px 6px;
        }
        .ant-table-cell {
          padding: 4px 6px;
          // .ant-input-number {
          //   width: 90px;
          // }
        }
      }
      .ant-input-number .ant-input-number-input {
        height: 20px;
      }
    }
    .card-table-desc {
      margin-top: 20px;
      .ant-descriptions .ant-descriptions-title {
        font-size: 12px;
      }
      .ant-descriptions.ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-label,
      .ant-descriptions .ant-descriptions-item-content {
        font-size: 12px;
        line-height: 12px;
      }
      .ant-descriptions-view {
      }
      .descriptions-list {
        .descriptions-header {
          color: #1e6dff;
          margin-bottom: 5px;
        }
        .descriptions-item {
          display: flex;
          .descriptions-item-label {
            width: 80px;
            padding: 2px 5px;
            color: #ffffff;
            line-height: 25px;
            background-color: #1e6dff;
            font-size: 12px;
          }
          .descriptions-item-span {
            flex: 1;
            padding: 2px 5px;
            line-height: 25px;
            border: 0.5px solid #d9d9d9;
          }
        }
      }
    }
  }
}
</style>
