<script>
import { message } from 'ant-design-vue'
import { numberToFixed, debounce, fnc_excelData_yc_after, checkZero, tableSortFnc, roundToDotNumber, tableDefaultPage } from '@/utils/util'
import { mapGetters } from 'vuex'
import CVPCalMixIn from '../../cloud/calMixIn.vue'

import SliderInputBlock from '@/components/SliderInputBlock.vue'
import ToolsItemIntro from '@/components/ToolsItemIntro.vue'
import dataTable from './dataTable.vue'
import dataTableCompare from './dataTableCompare.vue'

const groupTypeName = { line: '产品线', type: '产品类别', customer: '客户', area: '区域', group: '业务组' }
export default {
  components: {
    SliderInputBlock,
    ToolsItemIntro,
    dataTable,
    dataTableCompare
  },
  data() {
    return {
      params_tax_per: 0,
      params_sold_per: 0,
      params_cost_per: 0,
      params_pass_per: 0,
      initDataState: '',

      downLoading: false,
      planType: 'base',
      planBase: '',
      baseSvp: '',
      detail: {},
      listQuery: {
        planType: 'seven'
      },

      tableList: [],

      onChangeParamsTimer: 0,
      hasActualityData: true,
      isPrint: false,

      remark_base: '',
      remark_extra: '',
      remark_base_time: '',
      remark_base_mobile: '',
      remark_extra_mobile: '',
      remark_extra_time: '',

      //// CVP工具I（基于BOM）已保存方案1
      cvpDetail: {},
      svp_tableData: [],
      svp_tableData_fixed: [],
      svp_tableData_mix: [],
      svp_tableData_mix_columns: [],
      scatter_tableData: [],
      //对比方案数据
      ycTableList: [],

      listQuery2: {
        id: '',
        id1: '',
        id2: '',
        id3: '',
        id4: ''
      },
      columnsCompare: [],
      tableDataCompare: [],

      submitLoading:false,
      mobileParams: {},
      initSettingState:false,

    }
  },
  mixins: [CVPCalMixIn],
  created() {
    this.listQuery.id = this.$route.query.id || 0
    this.planType = this.$route.query.planType == 'extra' ? 'extra' : 'base'

    this.getPlanDetail()
    // console.log('================this.route', this.$route)
    this.listQuery2.id = this.$route.query.id || 0
    this.listQuery2.id1 = this.$route.query.id1 || 0
    this.listQuery2.id2 = this.$route.query.id2 || 0
    this.listQuery2.id3 = this.$route.query.id3 || 0
    this.listQuery2.id4 = this.$route.query.id4 || 0

    this.getComparePlanDetail()
  },
  computed: {
    ...mapGetters(['getHasToolAuth']),
    getDetailName() {
      return this.detail.name || ''
    },
    getWaterName() {
      return this.detail.water_name || ''
    },
    getPlanName() {
      return this.detail.plan_name || ''
    },
    getPlanName_en() {
      return this.detail.plan_name_en || ''
    },
    userInfo() {
      return this.detail.userInfo || {}
    },
    getIsSave() {
      return this.detail.is_save || 0
    },
    getPlanLogo() {
      return this.detail.plan_logo || ''
    },
    getTitle() {
      return ''
    }
  },

  methods: {
   
    onParamsSettingChange(field,set=true){
      if(!this.initSettingState){
        return
      }
      
      if(set){
        if(field==='dataTable'){
          this.$store.state.app.cacheMobileParams['cycleTValue'] =  this.$refs.dataTable.cycleTValue
          this.$store.state.app.cacheMobileParams['tableData'] =  this.$refs.dataTable.tableData
          console.log('--dataTable',this.$store.state.app.cacheMobileParams)
        }else{
          this.$store.state.app.cacheMobileParams[field] = this[field]
        }
      }else{
        return this.$store.state.app.cacheMobileParams[field]
      }
    },

    initParamsSetting(){ //初始化配置参数
      
      this.initSettingState = true;
      let pageParams = this.mobileParams||{};
      if(!pageParams || !('cycleTValue' in pageParams)){
        pageParams = this.pageParams||{};
      }

      let cycleTValue = pageParams.cycleTValue || 0
      let tableData = pageParams.tableData || 0

      const cache_cycleTValue = this.onParamsSettingChange('cycleTValue',false)
      if(cache_cycleTValue != null){
        cycleTValue = cache_cycleTValue
      }

      const cache_tableData = this.onParamsSettingChange('tableData',false)
      if(cache_tableData != null){
        tableData = cache_tableData
      }

      this.$refs.dataTable.setTableData(cycleTValue, tableData)

    },
    changeSettingParams(){
      this.$network('/api/tools/reulstUpdateMobileParams', {id:this.listQuery.id,mobile_params:{
        cycleTValue: this.$refs.dataTable.cycleTValue,
        tableData: this.$refs.dataTable.tableData || [],
      }})
        .then((res) => {
          this.$message.success('已更新')
        })
        .catch((err) => {
          
        })
    },

    getPlanDetail() {
      this.$network('/api/tools/planDetail', this.listQuery)
        .then((res) => {
          const data = res.data || {}
          const detail = data.detail || {}
          const pageParams = detail.page_params || {}
          this.mobileParams = detail.mobile_params || {}
          this.detail = detail
          this.planBase = this.detail.base || 'step4_cvp'
          this.$nextTick(() => {
            if (this.$refs.dataTable) {
              // this.$refs.dataTable.setTableData(pageParams.cycleTValue, pageParams.dataTableData)
              this.initParamsSetting()
            }
          })
        })
        .catch((err) => {
          console.error(err)
        })
    },
    getComparePlanDetail() {
      //结构化数据
      const structData = (item = {}) => {
        const name = item.name || ''
        const pageParams = item.page_params || {}
        const column = { width: 150, title: name, dataIndex: 'tax_per', align: 'right', resizable: false, ellipsis: true }
        const dataItem = {
          //投资总现金流入
          compare_tzzxjlr: pageParams.compare_tzzxjlr || 0,
          //投资总规模
          compare_tzzkm: pageParams.compare_tzzkm || 0,
          //前期投资
          compare_qqtz: pageParams.compare_qqtz || 0,
          //净现金流量
          compare_jxjll: pageParams.compare_jxjll || 0,
          //累计净现金流量
          compare_ljjxjll: pageParams.compare_ljjxjll || 0,
          //折现净现金流量
          compare_zxjxjll: pageParams.compare_zxjxjll || 0,
          //折现累计净现金流量
          compare_zxljjxjll: pageParams.compare_zxljjxjll || 0,
          //净利润
          compare_jlr: pageParams.compare_jlr || 0,
          //内部收益率
          compare_nbsyl: pageParams.compare_nbsyl || 0,
          //投资回收期 (动态)
          compare_tzhsq_dt: pageParams.compare_tzhsq_dt || 0,
          //空行
          null_row: ''
        }
        return { column, dataItem }
      }

      const PromiseAll = []
      Object.values(this.listQuery2).map((id) => {
        if (parseInt(id) > 0) {
          PromiseAll.push(this.$network('/api/tools/planDetail', { id: id }))
        }
      })
      Promise.all(PromiseAll).then((resAll) => {
        const columns = [
          {
            dataIndex: 'index',
            title: '序号',
            width: 50,
            align: 'center',
            customCell: (_, index) => {
              if (index === 0) {
                return { rowSpan: 2 }
              }
              if (index === 1) {
                return { rowSpan: 0 }
              }
              if (index === 2) {
                return { rowSpan: 2 }
              }
              if (index === 3) {
                return { rowSpan: 0 }
              }
              if (index === 4) {
                return { rowSpan: 2 }
              }
              if (index === 5) {
                return { rowSpan: 0 }
              }
            }
          },
          {
            dataIndex: 'name',
            title: '项',
            width: 120
            // customCell: (_, index) => {
            //   if (index === 0) {
            //     return { rowSpan: 2 }
            //   }
            //   if (index === 1) {
            //     return { rowSpan: 0 }
            //   }
            // }
          }
        ]
        const tableData = [
          { name: '投资总规模', field: 'compare_tzzkm', index: 1 },
          { name: '前期投资', field: 'compare_qqtz', index: 1 },
          // { name: '', field: 'null_row' },
          { name: '折现净现金流量', field: 'compare_zxjxjll', index: 2 },
          { name: '净利润', field: 'compare_jlr', index: 2 },
          // { name: '', field: 'null_row' },
          { name: '内部收益率', field: 'compare_nbsyl', index: 3 },
          { name: '投资回收期 (动态)', field: 'compare_tzhsq_dt', index: 3 }
        ]

        resAll.map((res, index) => {
          const data = res.data || {}
          const detail = data.detail || {}
          // const page_params = detail.page_params||{}

          if (index === 0) {
            this.detail = detail
          }
          const { column, dataItem } = structData(detail)
          const key = 'item_' + index
          column.dataIndex = key
          columns.push(column)
          tableData.map((item) => {
            const field = item.field
            item[key] = dataItem[field]
          })
        })

        // columns.forEach((item, index) => {
        //   item.customCell = this.mergeCells
        // })

        this.columnsCompare = columns
        this.tableDataCompare = tableData
      })
    }
  }
}
</script>
