<template>
  <div class="svp_echart" :class="isMobile ? 'svp_echart_mobile' : 'svp_echart_pc'">
    <div class="table-children">
      <!-- <div class="subtitle" v-if="!isMobile">相关维度数据明细表请勾选</div> -->
      <div class="checkbox-group" v-if="!isMobile">
        <a-checkbox-group v-model:value="checkedList" :options="checkedOptions" />
      </div>
    </div>

    <div class="echart_box" :id="echartId" :style="isMobile ? 'height: 100%' : getBodyWidth"></div>

    <div style="display: flex; align-items: center; justify-content: space-between">
      <a-radio-group v-model:value="scatterType" button-style="solid" style="margin-right: 4px" v-if="!isMobile">
        <a-radio-button :value="item" v-for="item in checkedList" :key="item">{{ svpRadio[item] }}</a-radio-button>
      </a-radio-group>

      <a-cascader
        v-if="!isMobile"
        multiple
        :fieldNames="{ label: 'title', value: 'dataIndex', children: 'children' }"
        placeholder="Please select"
        v-model:value="columns_filter"
        :options="columns_select"
        @change="onTableFieldChange($event, 'columns')"
      >
        <a href="#">选择列</a>
      </a-cascader>
    </div>

    <div class="profit-table" ref="tableScatter" v-if="!isMobile">
      <template v-for="(item, key) in tableDataList">
        <div class="table-children-box" :style="{ width: svp_echart_clientWidth + 'px' }" v-show="item.key == scatterType">
          <a-table
            :columns="columns"
            :pagination="topSumObj[item.key].pagination || false"
            class="cross-table"
            :data-source="item.data"
            :rowClassName="(_record, index) => (['sum_line'].indexOf(_record.lineType) > -1 ? 'gray-bg-def' : null)"
            size="small"
            bordered
            :scroll="{ x: 1600, y: gettableYheight }"
            @resizeColumn="
              (w, col) => {
                col.width = w
              }
            "
            @change="(pagination, filters, sorter, e) => onTableChange(item, pagination, filters, sorter, e)"
          >
            <template #bodyCell="{ column, text, record, index }">
              <template v-if="column.dataIndex === 'name' || column.dataIndex === 'type_name'">
                <!-- <a-tooltip color="#ffffff" trigger="focus" :title="text" :overlayInnerStyle="{ width: 'auto', color: '#000000' }">
                  <text> <ZoomInOutlined />{{ text }} </text>
                </a-tooltip> -->
                <div @click="handleShowData(record)" class="ellipsis" :title="text"><ZoomInOutlined />{{ text }}</div>
              </template>
              <template v-else-if="column.dataIndex === 'slid_money'">
                <a-tooltip color="#ffffff" trigger="click" :overlayInnerStyle="{ width: '300px' }">
                  <text :class="{ 'text-red': text * 1 < 0 }">{{ $dealThousands(text) }}</text>
                </a-tooltip>
              </template>
              <template v-else>
                <div>{{ $dealThousands(text) }}</div>
              </template>
            </template>
            <template #summary>
              <a-table-summary>
                <a-table-summary-row class="gray-bg-def-deep">
                  <a-table-summary-cell :index="0" align="center" v-if="columns_filter_items.indexOf('name') > -1">
                    <a-tooltip @click="showMoreTableList(item)">
                      <template #title>{{ topSumObj[item.key].pagination ? '点击查看全部' : '点击收起' }}</template>
                      其它
                      <template v-if="topSumObj[item.key].pagination">
                        <CaretDownOutlined />
                      </template>
                      <template v-else>
                        <CaretUpOutlined />
                      </template>
                    </a-tooltip>
                  </a-table-summary-cell>

                  <a-table-summary-cell :index="1" align="center" v-if="columns_filter_items.indexOf('type_name') > -1"> / </a-table-summary-cell>

                  <a-table-summary-cell align="right" v-if="columns_filter_items.indexOf('sold_num') > -1">
                    {{ $dealThousands(item.sold_num - (topSumObj[item.key] || {}).sum_sold_num || 0) }}
                  </a-table-summary-cell>
                  <a-table-summary-cell align="center" v-if="columns_filter_items.indexOf('sold_num__per') > -1"> / </a-table-summary-cell>
                  <a-table-summary-cell align="right" v-if="columns_filter_items.indexOf('price_come_in') > -1">
                    {{ $dealThousands(item.price_come_in - (topSumObj[item.key] || {}).sum_price_come_in || 0) }}
                  </a-table-summary-cell>
                  <a-table-summary-cell align="center" v-if="columns_filter_items.indexOf('price_come_in__per') > -1"> / </a-table-summary-cell>
                  <a-table-summary-cell align="right" v-if="columns_filter_items.indexOf('material_cost_money') > -1">
                    {{ $dealThousands(item.material_cost_money - (topSumObj[item.key] || {}).sum_material_cost_money || 0) }}
                  </a-table-summary-cell>
                  <a-table-summary-cell align="center" v-if="columns_filter_items.indexOf('material_cost_money__per') > -1"> / </a-table-summary-cell>
                  <a-table-summary-cell align="right" v-if="columns_filter_items.indexOf('slid_money') > -1">
                    <text :class="{ 'text-red': (item.slid_money - (topSumObj[item.key] || {}).sum_slid_money || 0) * 1 < 0 }">{{
                      $dealThousands(item.slid_money - (topSumObj[item.key] || {}).sum_slid_money || 0)
                    }}</text>
                  </a-table-summary-cell>
                  <a-table-summary-cell align="center" v-if="columns_filter_items.indexOf('slid_money__per') > -1"> / </a-table-summary-cell>
                </a-table-summary-row>
              </a-table-summary>

              <a-table-summary>
                <a-table-summary-row class="gray-bg-def-deep">
                  <a-table-summary-cell :index="0" align="center" v-if="columns_filter_items.indexOf('name') > -1"> 合计 </a-table-summary-cell>

                  <a-table-summary-cell :index="1" align="center" v-if="columns_filter_items.indexOf('type_name') > -1"> / </a-table-summary-cell>

                  <a-table-summary-cell align="right" v-if="columns_filter_items.indexOf('sold_num') > -1">
                    {{ $dealThousands(item.sold_num) }}
                  </a-table-summary-cell>
                  <a-table-summary-cell align="center" v-if="columns_filter_items.indexOf('sold_num__per') > -1"> / </a-table-summary-cell>
                  <a-table-summary-cell align="right" v-if="columns_filter_items.indexOf('price_come_in') > -1">
                    {{ $dealThousands(item.price_come_in) }}
                  </a-table-summary-cell>
                  <a-table-summary-cell align="center" v-if="columns_filter_items.indexOf('price_come_in__per') > -1"> / </a-table-summary-cell>
                  <a-table-summary-cell align="right" v-if="columns_filter_items.indexOf('material_cost_money') > -1">
                    {{ $dealThousands(item.material_cost_money) }}
                  </a-table-summary-cell>
                  <a-table-summary-cell align="center" v-if="columns_filter_items.indexOf('material_cost_money__per') > -1"> / </a-table-summary-cell>
                  <a-table-summary-cell align="right" v-if="columns_filter_items.indexOf('slid_money') > -1">
                    <text :class="{ 'text-red': item.slid_money * 1 < 0 }">{{ $dealThousands(item.slid_money) }}</text>
                  </a-table-summary-cell>
                  <a-table-summary-cell align="center" v-if="columns_filter_items.indexOf('slid_money__per') > -1"> / </a-table-summary-cell>
                </a-table-summary-row>
              </a-table-summary>
            </template>
            <!-- 空数据问题处理 -->
            <template #emptyText> </template>
          </a-table>
        </div>
      </template>
    </div>
    <DialogToolsSvpTable ref="DialogToolsSvpTable" :getWaterName="getWaterName" :logo="planLogo" :title="title" :subTitle="subTitle" :subName="subName" />
  </div>
</template>
  
<script>
import { ZoomInOutlined, CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons-vue'
import * as echarts from 'echarts'
import { mapGetters, mapState } from 'vuex'
import { numberToFixed, debounce, echartGraphic, tableSortFnc, tableDefaultPage, checkZero, tableDefautlLine, bigNumberTransform, dealThousands, chartNowSize } from '@/utils/util'
import DialogToolsSvpTable from '@/components/DialogTools/DialogToolsSvpTable.vue'
// echart变量
// let myChart = (id)=>{
//   var chartDom = document.getElementById(this.echartId)
//   myChart = echarts.init(chartDom, null, {
//       renderer: 'canvas',
//       useDirtyRect: false
//     })
// }

//echart变量
const myChart = {}

// const DIENSIION_CLUSTER_INDEX = 2;
// const CLUSTER_COUNT = 6;

// const groupTypeName = { line: '产品线', type: '产品类别', customer: '客户', area: '区域', group: '业务组' }
// const groupTypeArray = Object.keys(groupTypeName);
// const groupTypeValues = Object.values(groupTypeName);
const body_width = document.body.offsetWidth
const columns_select = [
  { width: 60, title: '纬度', dataIndex: 'name', align: 'center', resizable: true, ellipsis: true },
  { width: 140, title: '项', dataIndex: 'type_name', align: 'left', resizable: true, ellipsis: true },
  { width: 80, title: '销量', dataIndex: 'sold_num', align: 'right', resizable: true, ellipsis: true, ...tableSortFnc('sold_num') },
  { width: 80, title: '销量占比', dataIndex: 'sold_num__per', align: 'right', resizable: true, ellipsis: true, ...tableSortFnc('sold_num__per') },
  { width: 120, title: '收入', dataIndex: 'price_come_in', align: 'right', resizable: true, ellipsis: true, ...tableSortFnc('price_come_in') },
  { width: 80, title: '收入占比', dataIndex: 'price_come_in__per', align: 'right', resizable: true, ellipsis: true, ...tableSortFnc('price_come_in__per') },
  { width: 80, title: '变动成本', dataIndex: 'material_cost_money', align: 'right', resizable: true, ellipsis: true, ...tableSortFnc('material_cost_money') },
  { width: 80, title: '成本占比', dataIndex: 'material_cost_money__per', align: 'right', resizable: true, ellipsis: true, ...tableSortFnc('material_cost_money__per') },
  { width: 100, title: '边际收益', dataIndex: 'slid_money', align: 'right', resizable: true, ellipsis: true, ...tableSortFnc('slid_money') },
  { width: 120, title: '边际收益占比', dataIndex: 'slid_money__per', align: 'right', resizable: true, ellipsis: true, ...tableSortFnc('slid_money__per') }
]

export default {
  name: 'SvpScatter',
  components: {
    ZoomInOutlined,
    DialogToolsSvpTable,
    CaretDownOutlined,
    CaretUpOutlined
  },
  props: {
    isMobile: {
      type: Boolean,
      default: false
    },
    forceShowEchart: {
      type: Boolean,
      default: false
    },
    echartId: {
      type: String,
      required: true
    },
    excelData_yc: {
      type: Array,
      required: true
    },
    isPrint: {
      type: Boolean,
      default: false
    },
    planType: {
      type: String,
      default: ''
    },
    getWaterName: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    subTitle: {
      type: String,
      default: ''
    },
    planName: {
      type: String,
      default: ''
    },
    planLogo: {
      type: String,
      default: ''
    },
    subName: {
      type: String,
      default: ''
    },
    numberFnc: {
      type: Function,
      default: null
    }
  },
  data() {
    const cArr = body_width < 1919 ? [['name'], ['type_name'], ['slid_money']] : [['name'], ['type_name'], ['sold_num'], ['slid_money']]
    return {
      svp_echart_clientWidth: 0,
      pagination: tableDefaultPage(),
      checkedOptions: [],
      scatterType: 'customer',
      checkedList: ['customer'],
      groupArray: [],
      tableDataList: [],
      debounce: () => {},

      topSumObj: {},
      legendSelectNames: [],
      legendSelectNamesSelected: {},

      // columns_filter: [['name'], ['type_name'], ['sold_num'], ['price_come_in'], ['material_cost_money'], ['slid_money']],
      columns_filter: cArr,
      columns_select: columns_select,
      columns: [],
      svpRadio: {
        line: '产品线',
        type: '产品类别',
        customer: '客户',
        area: '区域',
        group: '业务组'
      }

      // myChart:{},
    }
  },
  computed: {
    ...mapGetters(['getHasToolAuth']),
    // excelData_yc() {
    //   return this.$store.state.tools.excelData_yc
    // },
    sum_sold_num() {
      return this.getSum_tableData('sold_num')
    },
    sum_price_come_in() {
      return this.getSum_tableData('price_come_in')
    },
    sum_material_cost_money() {
      return this.getSum_tableData('material_cost_money')
    },
    sum_slid_money() {
      return this.getSum_tableData('slid_money')
    },
    conditionTableData() {
      return this.tableData.filter((e) => this.checkedList.indexOf(e.key) > -1)
    },
    columns_filter_items() {
      return this.columns_filter.map((item) => {
        return item[0]
      })
    },
    getBodyWidth() {
      return document.body.clientWidth < 1441 ? 'height:250px' : document.body.clientWidth > 1441 && document.body.clientWidth < 1919 ? 'height:320px' : 'height:350px'
    },
    gettableYheight() {
      return document.body.clientWidth < 1441 ? 90 : document.body.clientWidth > 1441 && document.body.clientWidth < 1919 ? 100 : 140
    }
  },
  watch: {
    checkedList(value) {
      var selected = {}
      const groupTypeName = this.getScatterList()

      for (let key in groupTypeName) {
        selected[groupTypeName[key]] = value.indexOf(key) > -1
      }
      //更新图标
      this.legendSelectNamesSelected = selected
      this.setEchart({ legend: { selected: selected } })
    },
    planType(value) {
      if (value == 'extra') {
        this.$nextTick(() => {
          setTimeout(() => {
            myChart[this.echartId].resize()
          }, 200)
        })
      }
    },
    excelData_yc(value) {
      // console.log(value)
      let groupObject = {}
      const groupTypeName = this.getScatterList()

      value.map(function (item) {
        for (let key in groupTypeName) {
          const itemValue = item[key]
          const name = groupTypeName[key]
          if (!(name in groupObject)) {
            groupObject[name] = {}
          }
          if (!(itemValue in groupObject[name])) {
            groupObject[name][itemValue] = {
              name: name,
              key: key,
              type_name: itemValue,
              //销售收入
              price_come_in: 0,
              //变动成本
              material_cost_money: 0,
              //销量
              sold_num: 0,
              //边际收益
              slid_money: 0,
              //记录组合的数据
              list: []
            }
          }
          const sold_num = item.sold_num * 1
          //产量
          const pass_sold_num = item.pass_sold_num * 1

          item.material_cost_money = numberToFixed(item.material_cost * 1 * pass_sold_num)

          item.slid_money = numberToFixed(item.price_come_in * 1 - item.material_cost_money)
          item.price_come_in = numberToFixed(item.price_come_in)

          groupObject[name][itemValue]['price_come_in'] += item.price_come_in * 1
          groupObject[name][itemValue]['material_cost_money'] += item.material_cost_money * 1
          groupObject[name][itemValue]['sold_num'] += sold_num
          groupObject[name][itemValue]['list'].push(item)

          //边际贡献
          // const slid_money = numberToFixed(item.price_come_in - item.material_cost)
          // groupObject[name][itemValue]['slid_money'] +=  groupObject[name][itemValue]['price_come_in'] - groupObject[name][itemValue]['material_cost_money'];
        }
      })

      for (let _key in groupObject) {
        for (let _member in groupObject[_key]) {
          groupObject[_key][_member]['price_come_in'] = numberToFixed(groupObject[_key][_member]['price_come_in'])
          groupObject[_key][_member]['material_cost_money'] = numberToFixed(groupObject[_key][_member]['material_cost_money'])
          groupObject[_key][_member]['sold_num'] = numberToFixed(groupObject[_key][_member]['sold_num'])
          groupObject[_key][_member]['slid_money'] = numberToFixed(groupObject[_key][_member]['price_come_in'] - groupObject[_key][_member]['material_cost_money'])
        }
      }

      let tableDataList = []
      let topSumObj = {}

      let echartObject = {}
      for (let typeKey in groupTypeName) {
        const name = groupTypeName[typeKey]
        echartObject[name] = {
          typeKey: typeKey,
          name: name,
          type: 'scatter',
          emphasis: {
            focus: 'series'
          },
          markArea: {
            silent: true,
            itemStyle: {
              color: 'transparent',
              borderWidth: 0.5,
              borderType: 'dashed'
            },
            data: [
              [
                { name: name, xAxis: 'min', yAxis: 'min' },
                { xAxis: 'max', yAxis: 'max' }
              ]
            ]
          },
          markPoint: {
            symbolSize: 34,
            label: {
              formatter: (params) => {
                return this.isMobile ? bigNumberTransform(params.value, true, '', 2, true) : numberToFixed(params.value, 2)
              }
            },
            data: [
              { groupName: name, type: 'max', name: 'Max' },
              { groupName: name, type: 'min', name: 'Min' }
            ]
          },
          markLine: {},
          data: []
        }

        //表格数据
        let sum_price_come_in = 0
        let sum_material_cost_money = 0
        let sum_sold_num = 0
        let sum_slid_money = 0

        let tableData = []

        for (let memberKey in groupObject[name]) {
          const item = groupObject[name][memberKey]
          echartObject[name].data.push([numberToFixed(item.sold_num), numberToFixed(item.slid_money), memberKey])
          sum_price_come_in = sum_price_come_in + item.price_come_in * 1
          sum_material_cost_money = sum_material_cost_money + item.material_cost_money * 1
          sum_sold_num = sum_sold_num + item.sold_num * 1
          sum_slid_money = sum_slid_money + item.slid_money * 1

          const sum_coll_number = sum_price_come_in * 1 + sum_material_cost_money * 1 + sum_sold_num * 1 + sum_slid_money * 1

          tableData.push({
            name: name,
            key: typeKey,
            list: item.list,
            lineType: 'line',
            type_name: memberKey,

            price_come_in: item.price_come_in,
            price_come_in__per: numberToFixed(checkZero(sum_coll_number) ? 0 : (item.price_come_in / sum_coll_number) * 100),

            material_cost_money: item.material_cost_money,
            material_cost_money__per: numberToFixed(checkZero(sum_coll_number) ? 0 : (item.material_cost_money / sum_coll_number) * 100),

            sold_num: item.sold_num,
            sold_num__per: numberToFixed(checkZero(sum_coll_number) ? 0 : (item.sold_num / sum_coll_number) * 100),

            slid_money: item.slid_money,
            slid_money__per: numberToFixed(checkZero(sum_coll_number) ? 0 : (item.slid_money / sum_coll_number) * 100)
          })
        }
        const sortTableData = tableData.sort(tableSortFnc('slid_money').sorter.compare)
        tableDataList.push({
          name: '合计',
          key: typeKey,
          price_come_in: sum_price_come_in,
          material_cost_money: sum_material_cost_money,
          sold_num: sum_sold_num,
          slid_money: sum_slid_money,
          sum_coll_number: sum_price_come_in * 1 + sum_slid_money * 1 + sum_sold_num * 1 + sum_slid_money * 1,
          price_come_in__per: '/',
          material_cost_money__per: '/',
          sold_num__per: '/',
          slid_money__per: '/',
          data: sortTableData
        })

        topSumObj[typeKey] = {
          sum_price_come_in: 0,
          sum_material_cost_money: 0,
          sum_sold_num: 0,
          sum_slid_money: 0,
          upSortData: [],
          pagination: tableDefaultPage()
        }

        // tableData.push({
        //   // 'name':name+'合计',
        //   'name':'合计',
        //   'key': typeKey,
        //   'lineType':'sum_line',
        //   'type_name':'/',
        //   'price_come_in' :sum_price_come_in,
        //   'material_cost_money':sum_material_cost_money,
        //   'sold_num':sum_sold_num,
        //   'slid_money':sum_slid_money,
        // })

        //x轴均值
        let xAVG = 0
        const dataLen = echartObject[name].data.length
        echartObject[name].data.map((xAxisItem) => (xAVG += xAxisItem[0] * 1))
        xAVG = dataLen == 0 ? 0 : xAVG / dataLen
        echartObject[name].markLine = {
          lineStyle: { type: 'solid' },
          label: {
            formatter: function (param) {
              return Number(param.value).toFixed(0) // 设置精度为两位小数
            },
            position: 'insideEndTop', // 显示标签
            show: true,
            // 设置字体样式
            textStyle: {
              // 设置字体大小
              fontSize: this.isMobile ? 10 : chartNowSize(12)
            }
          },
          // data: [{ type: 'average', name: 'AVG' }, { xAxis: this.isMobile ? bigNumberTransform(xAVG, true, "",0,true) : xAVG }]
          data: [{ type: 'average', name: 'AVG' }, { xAxis: xAVG }]
        }
      }
      this.topSumObj = topSumObj
      this.tableDataList = tableDataList
      const groupArray = Object.values(echartObject)
      if (!this.legendSelectNames.length) {
        const legendSelectNames = []
        const legendSelectNamesSelected = {}
        groupArray.map((item) => {
          legendSelectNames.push(item.name)
          legendSelectNamesSelected[item.name] = this.checkedList.indexOf(item.typeKey) > -1
        })
        this.legendSelectNames = legendSelectNames
        this.legendSelectNamesSelected = legendSelectNamesSelected
      }

      this.groupArray = groupArray

      //计算其它加个
      this.$nextTick(() => {
        tableDataList.map((item) => {
          this.calOtherSum(item, item.data)
        })

        this.$emit('scatterData', { tableDataList, topSumObj })
      })
    },
    groupArray: {
      deep: true,
      handler: function () {
        this.debounce()
      }
    },
    columns_filter: {
      immediate: true, //首次加载的时候执行函数
      // deep:true, // 深入观察,监听数组值,对象属性值的变化
      handler(value) {
        const columnsSelected = value.map((item) => item[0])
        const columns = []
        columns_select.map((item, columnIndex) => {
          if (columnsSelected.indexOf(item.dataIndex) > -1) {
            columns.push(item)
          }
        })
        this.columns = columns
        // console.log('=======================this.columns', this.columns)
      }
    }
  },
  mounted() {
    const groupTypeName = this.getScatterList()
    const checkedOptions = []
    for (let key in groupTypeName) {
      checkedOptions.push({ label: groupTypeName[key], value: key })
    }
    this.checkedOptions = checkedOptions
    this.debounce = debounce(this._drawEchart)
    const svp_echart = document.getElementsByClassName('svp_echart')
    this.svp_echart_clientWidth = svp_echart[0].clientWidth - 20
    // console.log(myChart)
    var chartDom = document.getElementById(this.echartId)

    myChart[this.echartId] = echarts.init(chartDom, null, {
      renderer: 'canvas',
      useDirtyRect: false
    })
  },
  methods: {
    getScatterList() {
      let obj = {}
      if (this.forceShowEchart || this.getHasToolAuth('tools1_scatter_full')) {
        obj = { line: '产品线', type: '产品类别', customer: '客户', area: '区域', group: '业务组' }
      } else if (this.getHasToolAuth('tools1_scatter_normal')) {
        obj = { type: '产品类别', customer: '客户' }
      }
      return obj
    },
    handleShowData(row) {
      this.$refs.DialogToolsSvpTable.showDialog(row)
    },
    onTableFieldChange(value, field) {
      this[field + '_filter'] = value
    },

    showMoreTableList(item) {
      const selectKey = item.key || ''
      // const selectBlock = this.topSumObj[selectKey]||{}

      if (this.topSumObj[selectKey].pagination === false) {
        this.topSumObj[selectKey].pagination = tableDefaultPage()
      } else {
        this.topSumObj[selectKey].pagination = false
      }
      this.calOtherSum(item, this.topSumObj[selectKey].upSortData, false)
    },
    //计算其它加个
    calOtherSum(item, currentDataSource, recordUpData = true) {
      const selectKey = item.key || ''
      const selectBlock = this.topSumObj[selectKey] || {}
      const totalObj = {}
      for (let key in selectBlock) {
        if (key == 'upSortData') continue

        const pageSize = this.pagination.pageSize || 0
        const field = key.replace('sum_', '')
        const topSumObj_field = key
        totalObj[topSumObj_field] = 0
        let total = 0
        for (let i = 0; i < currentDataSource.length; i++) {
          const info = currentDataSource[i]

          if (this.topSumObj[selectKey].pagination !== false && i >= tableDefautlLine) {
            break
          }
          totalObj[topSumObj_field] = totalObj[topSumObj_field] + info[field] * 1
        }
      }

      if (recordUpData) {
        totalObj.upSortData = currentDataSource
      } else {
        totalObj.upSortData = selectBlock.upSortData
      }
      totalObj.pagination = this.topSumObj[selectKey].pagination //tableDefaultPage()
      this.topSumObj[selectKey] = totalObj
    },
    onTableChange(item, pagination, filters, sorter, { currentDataSource, action }) {
      // console.log('>>>>>>>>>>>>>>>>>> onTableChange <<<<<<<<<<<<<<<<<<<')
      if (action == 'sort') {
        return this.calOtherSum(item, currentDataSource)
      }
    },

    getSum_tableData(field) {
      let money = 0
      this.conditionTableData.map(function (item) {
        if (item.lineType !== 'sum_line') {
          money += +item[field]
        }
      })
      return numberToFixed(money)
    },
    drawEchart() {
      this.debounce()
    },
    setEchart(options = {}) {
      // 释放资源
      myChart[this.echartId].setOption(options)
    },
    _drawEchart() {
      const _isMobile = this.isMobile
      const that = this
      const option = {
        title: {
          text: '',
          subtext: ''
        },
        grid: _isMobile
          ? {
              top: '35',
              left: '0',
              right: '35',
              bottom: '3%',
              containLabel: true
            }
          : {
              top: '20%',
              left: '3%',
              right: '10%',
              bottom: '3%',
              containLabel: true
            },
        tooltip: {
          // trigger: 'axis',
          showDelay: 0,
          formatter: function (params) {
            const data = params.data || []
            const showName = data[2] || ''
            if (params.value.length > 1) {
              if (that.numberFnc) {
                return params.seriesName + ': ' + showName + ' <br/>' + '销量: ' + that.numberFnc(params.value[0], 2, true) + ' <br/>' + '边际贡献：' + that.numberFnc(params.value[1])
              } else {
                return params.seriesName + ': ' + showName + ' <br/>' + '销量: ' + numberToFixed(params.value[0], 2) + ' <br/>' + '边际贡献：' + numberToFixed(params.value[1], 2)
              }
            } else {
              if (that.numberFnc) {
                if (params.componentType === 'markLine') {
                  return 'Avg <br/>' + '边际贡献: ' + that.numberFnc(params.value) + ' '
                } else {
                  return params.data.groupName + ' <br/>' + '边际贡献: ' + that.numberFnc(params.value) + ' '
                }
              } else {
                if (params.componentType === 'markLine') {
                  return 'Avg <br/>' + '边际贡献: ' + numberToFixed(params.value, 2) + ' '
                } else {
                  return params.data.groupName + ' <br/>' + '边际贡献: ' + numberToFixed(params.value, 2) + ' '
                }
              }
            }
          },
          axisPointer: this.isMobile
            ? {}
            : {
                show: true,
                type: 'cross',
                lineStyle: { type: 'dashed', width: 1 }
              }
        },
        toolbox: this.isMobile
          ? {}
          : {
              top: 20,
              itemSize: chartNowSize(12),
              feature: {
                dataZoom: {},
                brush: {
                  type: ['rect', 'polygon', 'clear']
                }
              }
            },
        legend: Object.assign(
          {
            data: this.legendSelectNames,
            selected: this.legendSelectNamesSelected,
            selectedMode: false
          },
          _isMobile
            ? {
                show: false,
                itemHeight: 5,
                textStyle: {
                  fontSize: 8
                },
                itemStyle: {},
                lineStyle: {
                  width: 1,
                  inactiveWidth: 1
                }
              }
            : {
                show: false,
                textStyle: {
                  fontSize: chartNowSize(12)
                }
              }
        ),

        xAxis: [
          {
            type: 'value',
            scale: true,
            axisLabel: {
              formatter: _isMobile ? '{value}' : '{value} 销量'
            },
            name: '销量',
            nameLocation: 'end',
            nameTextStyle: {
              fontSize: _isMobile ? 10 : chartNowSize(12)
            },

            splitLine: {
              show: false
            },
            axisLabel: {
              textStyle: {
                fontSize: _isMobile ? 10 : chartNowSize(12) // 字体大小
              },
              formatter: function (value, index) {
                // 在这里对y轴的数值进行转换处理
                if (that.numberFnc) {
                  return parseInt(value, 0)
                } else {
                  // return value+' 销量'
                  return value
                }
              }
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            scale: true,
            name: '边际贡献',
            nameLocation: 'end',
            nameTextStyle: {
              fontSize: _isMobile ? 10 : chartNowSize(12)
            },
            axisLabel: {
              textStyle: {
                fontSize: _isMobile ? 10 : chartNowSize(12) // 字体大小
              },
              formatter: function (value) {
                // 在这里对y轴的数值进行转换处理
                if (that.numberFnc) {
                  return that.numberFnc(value, 0)
                } else {
                  // return value+' 边际贡献';
                  return Math.round(value > 10000 || value < -10000 ? value / 10000 : value)
                }
              }
            },
            splitLine: {
              show: false
            }
          }
        ],

        graphic: echartGraphic(_isMobile),
        series: this.groupArray
      }
      // 释放资源
      myChart[this.echartId].setOption(option)
    },
    //输出图片
    generateImage() {
      return myChart[this.echartId].getDataURL('png')
    }
  }
}
</script>
  
 <style scoped>
.checkbox-group {
  margin-bottom: 4px;
}
.table-children-box,
.table-children-ladderbox {
  width: 100%;
  /* padding: 15px 0px; */
}

.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>
  