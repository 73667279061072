<template>
  <div class="router-box">
    <div class="drag-box" style="margin-top: -10px" v-drag>
      <div class="drag-box-select">
        <!-- <div style="margin-right:10px;">
                    <a-radio-group v-model:value="selectRadioValue" :style="radioStyle" @change="changeSelectRadio">
                        <a-radio   :value="item.value" v-for="item in options1" :key="item.value+'radio'">{{item.label}}</a-radio>
                    </a-radio-group>
                </div>
                <div>
                    <a-checkbox-group v-model:value="selectGroup" style="width: 100px" @change="changeSelectGroup">
                        <a-row>
                            <a-col :span="24" v-for="item in options" :key="item.value">
                                <a-checkbox :value="item.value">{{item.label}}</a-checkbox>
                            </a-col>
                        </a-row>
                    </a-checkbox-group>
                </div> -->
        <div class="drag-box-row" style="margin-right: 10px">
          <a-radio-group v-model:value="selectRadioValue" :style="radioStyle" @change="changeSelectRadio">
            <a-radio :value="item.value" v-for="item in options1" :key="item.value + 'radio'">{{ item.label }}</a-radio>
          </a-radio-group>
        </div>
        <div class="drag-box-row" style="width: 100px">
          <a-checkbox v-model:checked="item.checked" v-for="item in options" :key="item.value + 'check'" @change="changeSelectGroup($event, item.value)">{{ item.label }}</a-checkbox>
        </div>
      </div>

      <!-- <div style="cursor: pointer; margin-top:14px;">
                <a-tag v-for='(item,index) in allselectList' :key="item.value+'tag'" v-drag>{{index + 1}}{{item.label}}</a-tag>
            </div>   -->
    </div>
    <div>
      <div :id="echartId" :style="{ marginTop: '10px', width: '100%', height: getBodyWidth }"></div>
    </div>
  </div>
  <!-- <a-select
                v-model:value="selectGroup"
                :options="options"
                mode="multiple" 
                placeholder="Please select"
                style="width: 100%" 
            >
            </a-select> -->
</template>

<script>
import * as echarts from 'echarts'
import { message } from 'ant-design-vue'
import { chartNowSize } from '@/utils/util'
export default {
  name: 'EchartEnergy',
  components: {},
  props: {
    isMobile: {
      type: Boolean,
      default: false
    },
    data: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: '桑葚图'
    }
  },
  data() {
    return {
      allselectList: [],
      selectGroup: ['no', 'customer', 'line', 'type', 'area', 'group'],
      selectRadioValue: 'sold_num',
      options: [
        { label: '产品编码', value: 'no', checked: true },
        { label: '产品线', value: 'line', checked: true },
        { label: '产品类别', value: 'type', checked: true },
        { label: '客户', value: 'customer', checked: true },
        { label: '区域', value: 'area', checked: true },
        { label: '业务组', value: 'group', checked: true }
      ],
      options1: [
        { label: '销售额', value: 'sold_num' },
        { label: '销售收入', value: 'sold_come_in' },
        { label: '边际收益', value: 'side_money' }
      ],
      allinList: [],
      buttonList: [],
      group: {
        name: 'pageButton',
        pull: false,
        put: false
      },
      radioStyle: {
        display: 'flex',
        'flex-direction': 'column',
        width: '100%',
        height: '30px',
        lineHeight: '30px'
      },
      draggedItem: null,
      draggedIndex: null,
      dialogSelectedApp: '',
      visible: false,
      echartId: 'echart-' + new Date().getTime() + '-' + Math.ceil(Math.random() * 10),
      nodeType: 'customer',
      echart: undefined,
      echartInit: false,
      echartData: {
        nodes: [],
        links: []
      },
      startNodeCounts: {}
    }
  },
  computed: {
    getBodyWidth() {
      return document.body.clientWidth < 1441
        ? document.body.clientHeight - 200 + 'px'
        : document.body.clientWidth > 1441 && document.body.clientWidth < 1919
        ? document.body.clientHeight - 230 + 'px'
        : document.body.clientHeight - 260 + 'px'
    },
    getGroupWidth() {
      return document.body.clientWidth < 1441 ? 5 : document.body.clientWidth > 1441 && document.body.clientWidth < 1919 ? 7 : 9
    }
  },

  watch: {
    selectGroup: {
      handler(value) {
        // 顺序不变
        this.allselectList = []
        this.allinList = []
        this.selectGroup.forEach((i) => {
          this.options.forEach((n) => {
            if (i === n.value) {
              this.allselectList.push(n)
              this.allinList.push(i)
            }
          })
        })
        // //添加默认的radio
        // const matchingOption = this.options1.find(i => i.value === this.selectRadioValue);
        // this.allselectList.push(matchingOption)
        // this.allinList.push(matchingOption.value)
        this.handleEchart()
      }
    },
    selectRadioValue: {
      handler(newval, oldval) {
        // this.allinList = this.allinList.filter(i=>i!==oldval) // 过滤之前的
        // this.allinList.push(newval) // 添加新的

        // this.allselectList = this.allselectList.filter(i => i.value !== oldval);
        // const matchingOption = this.options1.find(i => i.value === newval);
        // this.allselectList.push(matchingOption)
        this.handleEchart()
      }
    },
    data: {
      handler(value) {
        this.handleEchart()
      }
    }
  },
  mounted() {
    // 顺序不变
    this.selectGroup.forEach((i) => {
      this.options.forEach((n) => {
        if (i === n.value) {
          this.allselectList.push(n)
          this.allinList.push(i)
        }
      })
    })
    this.init()
    this.handleData()
  },
  methods: {
    hasDuplicateNames(arr) {
      const sourceCounts = {}

      for (const person of arr) {
        if (sourceCounts[person.source]) {
          // 如果sourceCounts中已经存在这个name，说明找到了重复的name
          return 7
        }
        // 否则，将这个name添加到sourceCounts中，并初始化计数为1
        sourceCounts[person.source] = 1
      }

      // 遍历完所有对象后，如果没有找到重复的name，返回false
      return 20
    },
    selectNodeGap() {
      let startNodeCounts = {}

      // console.log('----------------------this.echartData.links', this.echartData.links)
      this.echartData.links.forEach(function (link) {
        // if (!startPoints[link.source]) {
        //   startPoints[link.source] = []
        // }
        // startPoints[link.source].push(link.target)

        if (!startNodeCounts[link.source]) {
          startNodeCounts[link.source] = 0
        }
        startNodeCounts[link.source]++
      })

      // console.log('----------------------startNodeCounts', startNodeCounts)

      this.startNodeCounts = startNodeCounts
      // console.log('---------------------------------this.startNodeCounts', this.startNodeCounts)

      // 标记节点并调整间距
      // for (let node of this.echartData.nodes) {
      //   let isStartPoint = !startPoints[node.name]
      //   // 在这里处理每个节点的isStartPoint结果
      //   // console.log('---------------------isStartPoint', isStartPoint)
      //   return isStartPoint
      // }
    },
    checkDuplicateNames(arr) {
      const sourceCounts = {}
      const results = []

      for (const person of arr) {
        if (sourceCounts[person.source]) {
          sourceCounts[person.source]++
        } else {
          sourceCounts[person.source] = 1
        }
      }

      for (const person of arr) {
        results.push(sourceCounts[person.source] > 1)
      }

      return results
    },
    changeSelectGroup(ev, value) {
      //判断allinList是否存在对应的checkbox值
      if (this.allinList.includes(value)) {
        this.allinList = this.allinList.filter((i) => i !== value)
        this.allselectList = this.allselectList.filter((i) => i.value !== value)
      } else {
        this.allinList.push(value)
        const itemOption = this.options.find((i) => i.value === value)
        this.allselectList.push(itemOption)
      }
      this.handleEchart()
    },
    init() {
      this.echart = echarts.init(document.getElementById(this.echartId), null, {
        renderer: 'canvas',
        useDirtyRect: false
      })
    },
    handleEchart() {
      const that = this
      try {
        that.handleData()
        that.drawEchart()
      } catch (e) {
        console.error(e)
      }
    },
    handleData() {
      this.echartData.nodes = []
      this.echartData.links = []
      const selectGroupLen = this.allselectList.length
      if (selectGroupLen <= 1) {
        return
      }
      const addNodesFnc = (type, name) => {
        const nodeIndex = this.echartData.nodes.findIndex((nodeItem) => {
          return nodeItem.type == type && nodeItem.name == name
        })
        if (nodeIndex === -1) {
          this.echartData.nodes.push({
            name: name,
            type: type
          })
        }
      }

      const addLinksFnc = (source, target, val) => {
        const nodeIndex = this.echartData.links.findIndex((nodeItem) => {
          return nodeItem.source == source && nodeItem.target == target
        })
        // console.log(source,target,nodeIndex)
        // if(nodeIndex===-1){
        this.echartData.links.push({
          source: source,
          target: target,
          value: val
        })
        // }else{
        //     this.echartData.links[nodeIndex].value += val*1
        // }
      }
      this.data.map((item) => {
        let _value = ''
        if (this.selectRadioValue === 'sold_num') {
          _value = item.price_come_in || '' //不含税总收入
        } else if (this.selectRadioValue === 'sold_come_in') {
          _value = item.sold_come_in || '' //销售收入
        } else {
          _value = item.side_money || '' //边际收益
        }
        for (let i = 0; i < selectGroupLen; i++) {
          const groupItem = this.allinList[i]
          const groupItem_next = this.allinList[i + 1] || ''
          addNodesFnc(groupItem, item[groupItem])

          if (groupItem_next) {
            addLinksFnc(item[groupItem], item[groupItem_next], _value)
          } else if (selectGroupLen === 1) {
            addLinksFnc(item[groupItem], '', _value)
          }
        }
      })
      this.selectNodeGap()

      console.log('---------------------------------this.echartData', this.echartData)
      // let sel = this.selectNodeGap()
      // console.log('-----------------selectNodeGap', this.selectNodeGap)
    },
    drawEchart() {
      const that = this
      const option = {
        title: {
          text: ''
        },

        tooltip: {
          trigger: 'item',
          triggerOn: 'mousemove',
          textStyle: {
            fontSize: chartNowSize(14) // 设置字体大小为14
          }
        },
        legend: {},
        series: [
          {
            type: 'sankey',
            // left: 50.0,
            top: 0,
            // right: 150.0,
            bottom: 10,
            nodeWidth: 20,
            nodeGap: this.getGroupWidth,
            // nodeWidth: this.selectNodeGap ? 20 : 10,
            // nodeGap: this.selectNodeGap ? 10 : 20,
            data: this.echartData.nodes,
            links: this.echartData.links,
            // layoutIterations: 0, // 不进行迭代布局，因为我们使用自定义布局
            // layout: function (nodeData, linkData, graph) {
            //   console.log('=========================nodeData,', nodeData)
            //   // 自定义布局逻辑
            //   nodeData.forEach(function (node) {
            //     // 根据起点下的节点数量来调整间隔
            //     let count = this.startNodeCounts[node.name] || 1
            //     let padding = 10 / count

            //     // 设置节点间隔
            //     node.padding = padding
            //   })
            // },
            label: {
              show: true,
              autoRotate: true,
              textStyle: {
                color: 'rgba(0,0,0,0.7)',
                fontFamily: "'Arial', ",
                fontSize: chartNowSize(12)
              }
            },
            labelLayout: function (params) {
              // console.log('=========================params', params)
            },

            emphasis: {
              focus: 'adjacency'
            },
            lineStyle: {
              color: 'gradient',
              curveness: 0.5
            }
          }
        ]
      }
      // 释放资源
      this.echart.setOption(option)
    }
  }
}
</script>

<style lang="less" scoped>
.router-box {
  position: relative;
  top: 0;
  right: 0;
}
.drag-box {
  position: absolute;
  top: 0;
  right: 0;
  width: fit-content;
  padding: 10px;
  border: 1px solid gainsboro;
  cursor: move;
  z-index: 9;
  &-select {
    display: flex;
    flex-direction: row;
  }
}
</style>