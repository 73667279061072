<template>
  <div class="text-red">* 本表只适用于原始导入数据的经营情况分析</div>
  <slot name="summary" :summary_market_per="summary_market_per" :summary_sum_sotck_money="summary_sum_sotck_money"></slot>
  <a-table class="swiper-no-swiping" ref="tableList" :columns="getTableColumns" :data-source="tableData" :pagination="pagination" size="small" bordered :scroll="isMobile ? { x: 430 } : {}">
    <template #bodyCell="{ column, record, text }">
      <template v-if="['name', 'serial_per_1', 'serial_per_2', 'summary_market_per'].indexOf(column.dataIndex) === -1">
        <div>{{ handleNumber(text) }}</div>
      </template>
    </template>

    <template #summary>
      <a-table-summary fixed>
        <a-table-summary-row class="gray-bg-def-deep">
          <a-table-summary-cell :index="0" align="center" v-show="getTableColumnsKeys.indexOf('name') > -1"> 总计 </a-table-summary-cell>
          <a-table-summary-cell align="right" v-show="getTableColumnsKeys.indexOf('cost') > -1"> {{ handleNumber(summary_cost) }} </a-table-summary-cell>
          <a-table-summary-cell align="right" v-show="getTableColumnsKeys.indexOf('cost_serial') > -1"> {{ handleNumber(summary_cost_serial) }} </a-table-summary-cell>
          <a-table-summary-cell align="right" v-show="getTableColumnsKeys.indexOf('cost_product_begin') > -1"> {{ handleNumber(summary_cost_product_begin) }} </a-table-summary-cell>
          <a-table-summary-cell align="right" v-show="getTableColumnsKeys.indexOf('cost_product_fifo') > -1"> {{ handleNumber(summary_cost_product_fifo) }} </a-table-summary-cell>
          <a-table-summary-cell align="right" v-show="getTableColumnsKeys.indexOf('serial_per_1') > -1"> {{ handleNumber(summary_serial_per_1) }}% </a-table-summary-cell>
          <a-table-summary-cell align="right" v-show="getTableColumnsKeys.indexOf('serial_per_2') > -1"> {{ handleNumber(summary_serial_per_2) }}% </a-table-summary-cell>
          <a-table-summary-cell align="right" v-show="getTableColumnsKeys.indexOf('sum_sotck_money') > -1"> {{ handleNumber(summary_sum_sotck_money) }} </a-table-summary-cell>
          <a-table-summary-cell align="right" v-show="getTableColumnsKeys.indexOf('summary_market_per') > -1"> {{ summary_market_per }} </a-table-summary-cell>
        </a-table-summary-row>
      </a-table-summary>
    </template>
  </a-table>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { numberToFixed, roundToDotNumber, debounce, checkZero } from '@/utils/util'
import { ExclamationCircleOutlined } from '@ant-design/icons-vue'
import ToolsItemIntro from '@/components/ToolsItemIntro.vue'

export default {
  name: 'SolutionExtraPlan_TRCC',
  components: {
    ExclamationCircleOutlined,
    ToolsItemIntro
  },
  props: {
    isMobile: {
      type: Boolean,
      default: false
    },
    numberFnc: {
      type: Function,
      default: null
    },
    hasActualityData: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      pagination: false,
      tableData: [],
      summary_market_per: '0.00',
      columns: []
    }
  },
  computed: {
    ...mapGetters([]),
    getTableColumns() {
      let columns = [
        { title: '成本中心', dataIndex: 'name', align: 'center' },
        { title: '总投入额', dataIndex: 'cost', align: 'right' },
        // { title: '净投入额', dataIndex: 'cost_jing', align: 'right' },
        { title: '工序完工成本', dataIndex: 'cost_serial', align: 'right' },
        { title: '产品完工成本', dataIndex: 'cost_product_begin', align: 'right' },
        { title: '本期产品销售成本(FIFO)', dataIndex: 'cost_product_fifo', align: 'right' },
        // { title: '本期产品销售成本(AVCO)', dataIndex: 'cost_product_avco', align: 'right' },
        // { title: '完工产品投入产出(%)（当期）', dataIndex: 'cost_product_complete_begin', align: 'right' },
        { title: '投入产出1工序级', dataIndex: 'serial_per_1', align: 'right' },
        { title: '投入产出2产品级', dataIndex: 'serial_per_2', align: 'right' },
        { title: '期初库存总额', dataIndex: 'sum_sotck_money', customCell: this.tableCustomCell, align: 'right' },
        { title: '投入产出3市场级（FIFO）', dataIndex: 'summary_market_per', customCell: this.tableCustomCell, align: 'right' }
        // { title: '本期产品销售成本(FIFO)', dataIndex: 'cost_product_fifo', align: 'right' },
        // { title: '完工产品投入产出(%)（滚动）', dataIndex: 'cost_product_complete_current', align: 'right' },
        // { title: '销售投入产出(%)（当期）', dataIndex: 'cost_sold_begin', align: 'right' },
        // { title: '销售投入产出(%)（滚动）', dataIndex: 'cost_sold_current', align: 'right' },
      ]
      if (this.isMobile) {
        columns = [
          { title: '成本中心', dataIndex: 'name', align: 'center', fixed: 'left' },
          { title: '总投入额', dataIndex: 'cost', align: 'right' },
          { title: '投入产出1工序级', dataIndex: 'serial_per_1', align: 'right', width: '70px' },
          { title: '投入产出2产品级', dataIndex: 'serial_per_2', align: 'right', width: '70px' },
          { title: '投入产出3市场级（FIFO）', dataIndex: 'summary_market_per', align: 'right', width: '70px' }
        ]
      }
      return columns
    },
    getTableColumnsKeys() {
      return this.getTableColumns.map((item) => {
        return item.dataIndex
      })
    },
    summary_cost() {
      return this.handleTableSum('cost')
    },
    summary_cost_serial() {
      return this.handleTableSum('cost_serial')
    },
    summary_cost_product_begin() {
      return this.handleTableSum('cost_product_begin')
    },
    summary_cost_product_fifo() {
      return this.handleTableSum('cost_product_fifo')
    },
    summary_serial_per_1() {
      return numberToFixed(checkZero(this.summary_cost) ? 0 : (this.summary_cost_serial / this.summary_cost) * 100)
    },
    summary_serial_per_2() {
      return numberToFixed(checkZero(this.summary_cost) ? 0 : (this.summary_cost_product_begin / this.summary_cost) * 100)
    },
    summary_sum_sotck_money() {
      return this.handleTableSum('sum_sotck_money')
    }
    // summary_market_per2(){
    //   const total = this.summary_cost*1 + this.summary_sum_sotck_money*1
    //   console.log('>>>>>>>>>>>>>>>>>>>>')
    //   console.log(  this.summary_cost_product_fifo , this.summary_cost , this.summary_sum_sotck_money)
    //   return numberToFixed( checkZero(total) ? 0 : this.summary_cost_product_fifo / total * 100)
    // },
  },
  mounted() {
    // this.createWatch_tableData()
  },
  methods: {
    handleNumber(number) {
      if (this.numberFnc) {
        return this.numberFnc(number)
      } else {
        return this.$dealThousands(number)
      }
    },
    createTableData(options, pageObj) {
      // const list = info[0]
      // const serialProductDataObj = info[1]

      const { fncResult_stockAndYc, fncResult_serialStand, fncResult_SerialProduction, step2Stock_data } = pageObj.fncSettingOptions(
        {
          params_tax_per: 0,
          params_sold_per: 0,
          params_cost_per: 0,
          params_pass_per: 0
        },
        true
      )

      const list = fncResult_SerialProduction.newTableData || []

      let sum_sotck_money = 0
      step2Stock_data.map((item) => {
        sum_sotck_money = numberToFixed(sum_sotck_money * 1 + item.money * 1)
      })
      let sum_cost = 0
      let sum_cost_product_fifo = 0
      const tableDataObj = {}
      // console.log(list)
      list.map((item) => {
        const key = item.cost_name || ''
        if (!key) {
          return
        }
        if (!(key in tableDataObj)) {
          tableDataObj[key] = {
            name: key,
            //总投入额
            cost: 0,
            //净投入额
            cost_jing: 0,
            //工序完成成本
            cost_serial: 0,
            //本期产品完工成本
            cost_product_begin: 0,
            //本期产品销售成本(FIFO)
            cost_product_fifo: 0,
            //本期产品销售成本(FIFO) 标准
            cost_product_fifo_stand: 0,
            //本期产品销售成本(AVCO)
            cost_product_avco: '',
            //完工产品投入产出（当期)
            cost_product_complete_begin: 0,
            //完工产品投入产出（滚动)
            cost_product_complete_current: 0,
            //销售投入产出（当期）
            cost_sold_begin: 0,
            //销售投入产出（滚动）
            cost_sold_current: 0,
            //投入产出1工序级
            serial_per_1: 0,
            //投入产出2产品级
            serial_per_2: 0,
            //期初库存
            sum_sotck_money: sum_sotck_money,
            summary_market_per: 0
          }
        }
        const cost = item.balance_start * 1 + item.deadline_material * 1 + item.deadline_worker * 1 + item.deadline_money * 1
        const product_num_sold_fifo = item.product_num_sold_fifo
        const product_num_sold_fifo_stand = item.product_num_sold_fifo_stand
        tableDataObj[key].cost = roundToDotNumber(tableDataObj[key].cost * 1 + cost * 1)

        tableDataObj[key].cost_jing = roundToDotNumber(tableDataObj[key].cost_jing * 1 + item.amount * 1)
        tableDataObj[key].cost_serial = roundToDotNumber(tableDataObj[key].cost_serial * 1 + item.amount * 1)
        tableDataObj[key].cost_product_begin = roundToDotNumber(tableDataObj[key].cost_product_begin * 1 + item.serial_product_in_stock_money * 1)
        tableDataObj[key].cost_product_fifo = roundToDotNumber(tableDataObj[key].cost_product_fifo * 1 + product_num_sold_fifo * 1)
        tableDataObj[key].cost_product_fifo_stand = roundToDotNumber(tableDataObj[key].cost_product_fifo_stand * 1 + product_num_sold_fifo_stand * 1)

        tableDataObj[key].cost_product_complete_begin = roundToDotNumber(checkZero(tableDataObj[key].cost_jing) ? 0 : (tableDataObj[key].cost_product_begin / tableDataObj[key].cost_jing) * 100)
        tableDataObj[key].cost_product_complete_current = roundToDotNumber(checkZero(tableDataObj[key].cost) ? 0 : (tableDataObj[key].cost_product_begin / tableDataObj[key].cost) * 100)
        tableDataObj[key].cost_sold_begin = roundToDotNumber(checkZero(tableDataObj[key].cost_jing) ? 0 : (tableDataObj[key].cost_product_fifo / tableDataObj[key].cost_jing) * 100)
        tableDataObj[key].cost_sold_current = roundToDotNumber(checkZero(tableDataObj[key].cost) ? 0 : (tableDataObj[key].cost_product_fifo / tableDataObj[key].cost) * 100)

        tableDataObj[key].serial_per_1 = roundToDotNumber(checkZero(tableDataObj[key].cost) ? 0 : (tableDataObj[key].cost_serial / tableDataObj[key].cost) * 100) + '%'

        tableDataObj[key].serial_per_2 = roundToDotNumber(checkZero(tableDataObj[key].cost) ? 0 : (tableDataObj[key].cost_product_begin / tableDataObj[key].cost) * 100) + '%'

        sum_cost = sum_cost * 1 + cost * 1
        sum_cost_product_fifo = sum_cost_product_fifo * 1 + product_num_sold_fifo * 1
      })
      const summary_market_per = roundToDotNumber(checkZero(sum_cost * 1 + sum_sotck_money * 1) ? 0 : (sum_cost_product_fifo / (sum_cost * 1 + sum_sotck_money * 1)) * 100) + '%'

      this.summary_market_per = summary_market_per

      const tableData = Object.values(tableDataObj)
      this.tableData = tableData.map((item) => {
        item.summary_market_per = summary_market_per
        return item
      })
      // console.log('00000000000000000000000000000')
      // console.log(tableDataObj)
      return tableDataObj
      // this.$store.commit('updateTools2State', { field: 'tools2_serial_group_object', value: tableDataObj })
    },
    handleTableSum(field) {
      let money = 0
      this.tableData.map((item) => {
        if (field === 'sum_sotck_money') {
          money = item[field]
        } else {
          money += item[field] * 1
        }
      })
      return roundToDotNumber(money)
    },
    tableCustomCell(item, index) {
      return { rowSpan: index === 0 ? this.tableData.length : 0 }
    }
  }
}
</script>

<style></style>
