<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'Register',
})
</script>
<template>
    <div class="main user-layout-register">
        <div class="user-register-box">
            <div class="register-steps">
                <a-steps :current="0" label-placement="vertical" :items="items" />
            </div>
            <div class="register-form">
                <a-form id="formRegister" layout="vertical" :model="form">

                    <a-form-item v-bind="validateInfos.phone">
                        <a-input :bordered="false" v-model:value="form.phone" placeholder="输入手机号">
                            <template #prefix>
                                <div class="ant-input-label addon">
                                    中 国 +86
                                </div>
                            </template>
                        </a-input>
                    </a-form-item>

                    <a-form-item v-bind="validateInfos.verify">

                        <a-input-group>
                            <a-input :bordered="false" v-model:value="form.verify" placeholder="输入验证码"
                                style="width: calc(100% - 100px)">
                                <template #prefix>
                                    <div class="ant-input-label">
                                        手机验证码
                                    </div>
                                </template>
                            </a-input>
                            <a-button type="primary" class="getCaptcha" :disabled="state.smsSendBtn"
                                @click.stop.prevent="getCaptcha">{{
                                    !state.smsSendBtn && '获取验证码' || (state.time + ' s') }}</a-button>
                        </a-input-group>
                    </a-form-item>

                    <a-form-item class="noStyle">
                        <a-checkbox v-model:checked="form.checkNick">我已阅读并同意
                            <a @click.prevent="$refs.popupContent.open('userProtocol')">《服务协议》</a>和
                            <a @click.prevent="$refs.popupContent.open('protectProtocol')">《个人信息保护政策》</a>
                        </a-checkbox>
                    </a-form-item>

                    <a-button type="primary" htmlType="submit" class="register-button" :loading="registerBtn"
                        @click.stop.prevent="handleSubmit" :disabled="registerBtn">注 册</a-button>

                    <router-link class="login" :to="{ path: '/user/login' }">使用已有账户登录</router-link>
                </a-form>
            </div>
        </div>
        <PopupContent ref="popupContent"/>
    </div>
</template>

<script lang="ts" setup name="Register">
import { ref, reactive, computed } from 'vue'
import baseService from '@/utils/http/axios'

import { Form, message } from 'ant-design-vue'
import { MailOutlined } from '@ant-design/icons-vue'
import { useRouter } from 'vue-router'
import { useGetCaptcha } from '@/store/modules/helper'
import ls from '@/utils/Storage'
import { CACHE_PHONE, } from '@/store/mutation-types'


const items = ref([
    {
        title: '验证手机号',
    },
    {
        title: '填写账号信息',
    },
    {
        title: '注册成功',
    }
]);
const router = useRouter()
const useForm = Form.useForm

// 表单相关
const form = reactive({
    phone: '',
    verify: '',
    type: 'reg-verify',
    checkNick: false
})

const rules = reactive({
    phone: [
        { required: true, message: '请输入正确手机号', pattern: /^1[3456789]\d{9}$/ },
        { validateTrigger: ['change', 'blur'] }
    ],
    verify: [{ required: true, message: '请输入验证码' }, { validateTrigger: 'blur' }]
})
const state = reactive({
    time: 60,
    level: 0,
    smsSendBtn: false,
    percent: 10,
    progressColor: '#FF0000'
})

const getCaptcha = (e) => {
    useGetCaptcha(e, validate, state, form)
}

const { validate, validateInfos } = useForm(form, rules)


const handleSubmit = () => {
    if (!form.checkNick) {
        message.error('请先阅读并同意《服务协议》、《个人信息保护政策》')
        return;
    }
    validate().then((res) => {
        baseService.post('/api/account/phoneRegVerify', {
            phone: form.phone,
            verify: form.verify,
        }).then(res => {

            ls.set(CACHE_PHONE, form.phone)

            router.push({ path: '/user/registerPersonSet', params: {} })
            console.log(res)
        }).catch(err => {
            console.error(err)
        })
    })
}

</script>
<style lang="less">
.user-register {
    &.error {
        color: #ff0000;
    }

    &.warning {
        color: #ff7e05;
    }

    &.success {
        color: #52c41a;
    }
}

.user-layout-register {
    .ant-input-group-addon:first-child {
        background-color: #fff;
    }

    .register-form {
        .ant-form-item {
            border: 1px solid #d9d9d9;

            &.noStyle {
                border: none;
            }

            .ant-select {
                flex: 1;
                width: 100%;
                padding: 8px 0px;

                .ant-select-selector {
                    padding: 0px 16px;
                }
            }

            .ant-input-group {
                display: flex;
                align-items: center;
            }

            .ant-input-group-compact {
                padding: 3px 0px;
            }

            .ant-input {
                padding: 12px 16px;
            }
        }
    }

    .picture-upload {
        display: flex;

        .ant-upload-wrapper.ant-upload-picture-card-wrapper {
            width: auto;

            .ant-upload.ant-upload-select {
                width: auto;
                height: auto;
            }
        }

        .picture-example {
            width: 210px;

            .picture-example-thumbnail {
                border: 1px dashed #d9d9d9;
                border-radius: 2px;
            }

            .picture-example-name {
                text-align: center;
                margin-top: 8px;
            }

        }

        .picture-button {
            background-color: #f6f9ff;
        }
    }
}
</style>
<style lang="less" scoped>
.user-layout-register {
    width: 1200px;
    min-height: 100%;
    padding: 2.5% 0;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;

    .user-register-box {
        width: 100%;
        padding: 12px;
        background-color: #ffffff;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .register-steps {
            width: 100%;
            padding: 24px 16%;
            border-bottom: 1px solid #d9d9d9;
        }

        .register-form {
            width: 640px;
            padding: 48px 0px 24px;
        }


    }

    .ant-input-label {
        min-width: 78px;
        text-align: justify;
        height: 100%;
        display: flex;
        align-items: center;

        &.addon {
            border-right: 1px solid #d9d9d9;
        }
    }

    .ant-input-group-label {
        min-width: 72px;
        text-align: justify;
        padding: 0px 0px 0px 11px;
        margin-inline-end: 4px;
    }

    .getCaptcha {
        display: block;
        width: 200px;
        height: 54px;
    }

    .register-button {
        width: 100%;
        height: 54px;
        font-size: 16px;
    }

    .login {
        float: right;
        line-height: 40px;
    }
}
</style>
