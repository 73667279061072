import baseService from '@/utils/http/axios';
import { numberToFixed, dealThousands, bigNumberTransform, baseURL, checkZero, priceLadderPriceKey } from './util';

import showPopContent from '@/hooks/showPopContent';
import { time } from 'console';

// 创建一个新的 axios 实例
export default function (app, store, router) {

    app.config.globalProperties.$showPopContent = showPopContent

    //初始化模板变量
    const tempDataInit = function (temp, obj, item) {
        for (let key in temp) {

            let value_type = typeof temp[key]
            let value = item[key];
            let default_value;
            // console.log(key,value)

            if (temp[key] instanceof Object) {
                var jsonObject = JSON.stringify(temp[key])
                if (jsonObject[0] === '[') {
                    default_value = []
                } else if (jsonObject[0] === '{') {
                    value = Object.assign({}, value || {})
                    let tempObj = tempDataInit(temp[key], Object.assign({}, obj[key] || {}), value);
                    if (Object.keys(tempObj).length) {
                        default_value = tempObj
                        value = tempObj
                    } else {
                        default_value = Object.assign({}, temp[key] || {})
                    }
                } else {
                    default_value = ""
                }
            } else if (value_type === 'string') {
                default_value = temp[key] + ''
                value = (value || '') + ""
            } else if (value === 0) {
                default_value = temp[key] || 0
            }
            obj[key] = value || default_value
        }
        return obj;
    }
    app.config.globalProperties.$checkZero = checkZero
    app.config.globalProperties.$baseURL = baseURL
    app.config.globalProperties.$tempDataInit = tempDataInit


    const formatZero = function (num) {
        const number = parseInt(num);
        return (number < 10 && number >= 0 ? '0' : '') + number;
    }

    const dateFormatTimeFnc = function (date, format) {
        var timeFormat = !format ? "YYYY-MM-DD HH:mm:ss" : format
        const year = date.getFullYear() + '';
        const month = formatZero(date.getMonth() + 1);
        const day = formatZero(date.getDate());
        const hour = formatZero(date.getHours());
        const minute = formatZero(date.getMinutes());
        const second = formatZero(date.getSeconds());

        if (timeFormat.indexOf('YYYY') > -1) {
            timeFormat = timeFormat.replace('YYYY', year)
        }
        if (timeFormat.indexOf('MM') > -1) {
            timeFormat = timeFormat.replace('MM', month)
        }
        if (timeFormat.indexOf('DD') > -1) {
            timeFormat = timeFormat.replace('DD', day)
        }
        if (timeFormat.indexOf('HH') > -1) {
            timeFormat = timeFormat.replace('HH', hour)
        }
        if (timeFormat.indexOf('mm') > -1) {
            timeFormat = timeFormat.replace('mm', minute)
        }
        if (timeFormat.indexOf('ss') > -1) {
            timeFormat = timeFormat.replace('ss', second)
        }
        return timeFormat;
    }
    app.config.globalProperties.$dateFormatTimeFnc = dateFormatTimeFnc

    const formatTime = function (time, format, step) {
        var timeFormat = !format ? "YYYY-MM-DD HH:mm:ss" : format
        var date = new Date()

        if (time === 'month01') {
            date = new Date(date.getFullYear(), date.getMonth())
        } else if (time === 'nextDay') {
            date = new Date()
            date.setTime(date.getTime() + 86400 * 1000 * step)
        } else if (parseInt(time) > 0) {
            date.setTime(time * 1000)
        }



        return dateFormatTimeFnc(date, format)
    }
    app.config.globalProperties.$formatTime = formatTime

    //日期月份
    app.config.globalProperties.$decMonth = function (datetime, num) {
        var date = new Date(datetime);
        date.setMonth(date.getMonth() - num)

        return dateFormatTimeFnc(date, "YYYY-MM-DD")
    }
    //日期月份
    app.config.globalProperties.$incMonth = function (datetime, num) {
        var date = new Date(datetime);
        date.setMonth(date.getMonth() + num)

        return dateFormatTimeFnc(date, "YYYY-MM-DD")
    }
    //两日期相差多少天
    app.config.globalProperties.$diffDay = function (datetime1, datetime2) {
        var date1 = (new Date(datetime1)).getTime();
        var date2 = (new Date(datetime2)).getTime();

        var diffMilliseconds = Math.abs(date2 - date1);
        var diffDays = Math.floor(diffMilliseconds / 86400000);
        return diffDays;
    }
    //两日期相差多少天
    app.config.globalProperties.$diffMonth = function (datetime1, datetime2) {
        var date1 = (new Date(datetime1))
        var date2 = (new Date(datetime2))
        return (Math.abs((date1.getFullYear() * 12 + date1.getMonth()) - (date2.getFullYear() * 12 + date2.getMonth())) + 1);
    }
    //两日期相差多少天
    app.config.globalProperties.$diffDay30 = function (datetime1, datetime2) {
        var date1 = (new Date(datetime1))
        var date2 = (new Date(datetime2))
        return (Math.abs((date1.getFullYear() * 12 + date1.getMonth()) - (date2.getFullYear() * 12 + date2.getMonth())) + 1) * 30;
    }
    app.config.globalProperties.$bigNumberTransform = bigNumberTransform

    //初始化模板变量
    app.config.globalProperties.$toFixed = function (number, dot) {
        var num = number ? (number * 1) : 0;
        var dotNum = parseInt(dot)

        return num.toFixed(dotNum ? dotNum : 2)
    }

    //初始化模板变量
    app.config.globalProperties.$multiRedirect = function (path, query) {
        app.config.globalProperties.$router.replace({ path: path, query: query })
    }
    //全局属性
    app.config.globalProperties.globalData = {
        upload_info: undefined,
    }

    //最接近的数值
    app.config.globalProperties.$numberCeilAbs = function (num) {
        const score = Math.abs(parseInt(num))
        if (score < 10) {
            return num;
        }
        const len = ('' + score).length;
        let calLen = len - 2;
        if (len <= 2) {
            calLen = len - 1;
        }
        let intNum = parseInt(score / Math.pow(10, calLen))

        // console.log(score)
        // console.log(calLen)
        // console.log(intNum)
        // console.log(Math.pow(10,calLen))

        return intNum * Math.pow(10, calLen) + Math.pow(10, calLen)
    }

    /**
     * 格式化数字
     * @param {String} number
     * @param {number} position
     */
    app.config.globalProperties.$numberToFixed = numberToFixed
    app.config.globalProperties.$dealThousands = dealThousands

    //销售预测阶梯价格处理

    app.config.globalProperties.$priceLadderPriceKey = priceLadderPriceKey
    app.config.globalProperties.$priceLadderSort = function (list) {
        const priceData = {}

        list.map((item) => {
            const key = priceLadderPriceKey(item);
            const num_start = parseFloat(item.num_start || 0)
            const num_end = parseFloat(item.num_end || 0)
            const price = parseFloat(item.price || 0)
            const full_num = parseFloat(item.full_num || 0)
            if (!(key in priceData)) {
                priceData[key] = {
                    item: item,
                    range: []
                }
            }
            priceData[key].range.push({
                num_start: num_start,
                num_end: num_end,
                price: price,
                use_num: 0,
            })
        })
        //排序
        Object.keys(priceData).map((key) => {
            priceData[key].range = priceData[key].range.sort((a, b) => a.num_start - b.num_start)
        })
        return priceData;
    },

        //添加网络请求插件
        app.config.globalProperties.$network = function (url, params, conf) {
            params = params || {}
            conf = Object.assign({}, {
                showErrMsg: true,
                ignoreParse: false,
                method: "POST",
                completeEvent: function (res) { },
            }, conf || {})
            var that = this

            return baseService.post(url, params)

            return new Promise(function (resolve, reject) {
                var headers = {}
                headers["Content-Type"] = 'application/json'
                headers[appConfig.tokenName] = 'Bearer ' + (store.state.token || '')
                headers[appConfig.langName] = store.state.providerLang || ''

                $.ajax(appConfig.host + url, {
                    type: conf.method,
                    dataType: 'json',
                    headers: headers,
                    data: conf.method.toUpperCase() === 'GET' ? params : JSON.stringify(params),
                    success: function (res) {
                        if (conf.ignoreParse) {
                            return resolve(res)
                        }
                        var code = res.code
                        var msg = res.msg
                        if (code === 1) {
                            resolve(res)
                        } else if (code === -1) {
                            app.config.globalProperties.$message({ message: msg, type: 'error' });
                            //清空数据
                            store.commit('setUserToken', {})
                            //跳转到登录页面
                            router.replace({ path: '/login' })
                        } else {
                            // alert(msg)
                            if (conf.showErrMsg) {
                                app.config.globalProperties.$message({ message: msg, type: 'error' });
                            }
                            reject(res)
                        }
                    },
                    error: function (err) {
                        console.log('network-err', err)
                        reject(err)
                    },
                    complete: function (res) {
                        conf.completeEvent(res)
                    }
                })
            })
        }

    //获取包含月份
    app.config.globalProperties.$getBeforeMonthGroup = (startMonth, times) => {
        if (!startMonth || !times) {
            return [];
        }
        const data = []
        for (let i = 0; i < times; i++) {
            const date = new Date(startMonth)
            date.setMonth(date.getMonth() - i)
            data.push(formatTime(date.getTime() / 1000, "YYYY-MM-DD", 0))
        }
        return data;
    }

    //获取某月份天数
    const getMonthDays = (month) => {
        const y = month.substr(0, 4)
        const m = month.substr(5, 2)
        const d = (new Date(parseInt(y), parseInt(m), 0)).getDate()
        return d;
    }
    app.config.globalProperties.$getMonthDays = getMonthDays

    //获取某月份天数
    app.config.globalProperties.$getMonthDate = (month) => {
        const y = month.substr(0, 4)
        const m = month.substr(5, 2)
        const d = getMonthDays(month)
        return y + '-' + m + '-' + d;
    }

    /*
    获取缓存数据-有就读取,没有 就网络请求
    obj-对象格式  {"数据类型":"缓存的键名"}
        获取商品分类 可使用 {goods_cate:goodsCateList}
    */
    app.config.globalProperties.$getCacheData = (function () {
        var moduleName = 'api';


        const apiUrl = '/' + moduleName + '/index/groupData';
        const cacheConfing = {}
        return function (obj, fnc, refresh, url) {
            const forceRefresh = refresh === true
            if (!(fnc instanceof Function) || !(obj instanceof Object)) {
                return
            }
            let query = {}
            let results = {};
            if (forceRefresh) {
                query = obj
            } else {
                Object.keys(obj).map(function (key) {
                    const valueKey = obj[key]
                    if (!cacheConfing[valueKey]) {
                        query[key] = valueKey
                    } else {
                        //绑定缓存数据
                        results[valueKey] = cacheConfing[valueKey]
                    }
                })
            }

            if (!Object.keys(query).length) {
                //返回数据
                fnc(results)
            } else {
                app.config.globalProperties.$network(apiUrl, query).then((res) => {
                    const data = res.data || {}

                    Object.keys(data).map(function (key) {
                        const value = data[key];
                        results[key] = value;
                        cacheConfing[key] = value;
                    })
                    //返回数据
                    fnc(results)
                })
            }



        }
    })()



    //全局属性
    app.config.globalProperties.$crossTable = function (record) {
        console.log('++++++crossTable')
        console.log(record)
        return {
            onClick: (event) => {
                console.log('++++++onMouseleave')
                console.log(event)
            },       // 点击行
            onDblclick: (event) => { },
            onContextmenu: (event) => { },
            onMouseenter: (event) => {
                console.log('++++++onMouseenter', record)
                console.log(event)
            },  // 鼠标移入行
            onMouseleave: (event) => {
                console.log('++++++onMouseleave', record)
                console.log(event)
            }
        }
    }

}

