<script>
import { message } from 'ant-design-vue'
import { numberToFixed, debounce, fnc_excelData_yc_after, fnc_excelData_yc_line, checkZero, tableSortFnc, roundToDotNumber, tableDefaultPage } from '@/utils/util'
import { mapGetters } from 'vuex'
import CVPCalMixIn from '../../cloud/calMixIn.vue'
import { htmlToPDF } from '@/utils/html2pdf'

import SliderInputBlock from '@/components/SliderInputBlock.vue'
import ToolsItemIntro from '@/components/ToolsItemIntro.vue'
import SolutionSvp from './SolutionSvp.vue'
import SolutionAnalysis from './SolutionAnalysis.vue'

const groupTypeName = { line: '产品线', type: '产品类别', customer: '客户', area: '区域', group: '业务组' }
export default {
  components: {
    SliderInputBlock,
    ToolsItemIntro,
    SolutionSvp,
    SolutionAnalysis
  },
  data() {
    return {
      params_tax_per: 0,
      params_sold_per: 0,
      params_cost_per: 0,
      params_pass_per: 0,
      initDataState: '',

      downLoading: false,
      planType: 'base',
      planBase: '',
      baseSvp: '',
      detail: {},
      listQuery: {},

      tableList: [],

      onChangeParamsTimer: 0,
      hasActualityData: true,
      isPrint: false,

      remark_base: '',
      remark_extra: '',
      remark_base_time: '',
      remark_base_mobile: '',
      remark_extra_mobile: '',
      remark_extra_time: '',

      //// CVP工具I（基于BOM）已保存方案1
      cvpDetail: {},
      svp_tableData: [],
      svp_tableData_fixed: [],
      svp_tableData_mix: [],
      svp_tableData_mix_columns: [],
      scatter_tableData: [],
      //对比方案数据
      ycTableList: [],

      //分析解读
      analysis: {},
      analySisList: [],

      auth_share_state: 0,
      authTools: [],

      
      submitLoading:false,
      pageParams: {},
      mobileParams: {},
      initSettingState:false,
    }
  },
  mixins: [CVPCalMixIn],
  created() {
    this.listQuery.id = this.$route.query.id || 0
    this.planType = this.$route.query.planType == 'extra' ? 'extra' : 'base'

    this.getPlanDetail()
  },
  computed: {
    ...mapGetters(['getHasToolAuth']),
    getDetailName() {
      return this.detail.name || ''
    },
    getWaterName() {
      return this.detail.water_name || ''
    },
    getPlanName() {
      return this.detail.plan_name || ''
    },
    getPlanName_en() {
      return this.detail.plan_name_en || ''
    },
    userInfo() {
      return this.detail.userInfo || {}
    },
    getIsSave() {
      return this.detail.is_save || 0
    },
    getPlanLogo() {
      return this.detail.plan_logo || ''
    },
    getTitle() {
      return ''
    }
  },

  methods: {

    
    onParamsSettingChange(field,set=true){
      if(!this.initSettingState){
        return
      }
      
      if(set){
        if(field==='SolutionSvp'){
          this.$store.state.app.cacheMobileParams[field] = this.$refs.SolutionSvp.getPageParams()
        }else{
          this.$store.state.app.cacheMobileParams[field] = this.$refs[field]
        }
        
      }else{
        return this.$store.state.app.cacheMobileParams[field]
      }
    },

    initParamsSetting(){ //初始化配置参数
      
      this.initSettingState = true;
      let pageParams = this.mobileParams||{};
      if(!pageParams || !('SolutionSvp' in pageParams)){
        pageParams = this.pageParams||{};
      }

      let SolutionSvp = pageParams.SolutionSvp || {}

      const cache_SolutionSvp = this.onParamsSettingChange('SolutionSvp',false)
      if(cache_SolutionSvp != null){
        SolutionSvp = cache_SolutionSvp
      }
      
      this.$refs.SolutionSvp.setPageParams(SolutionSvp)
      
      

    },
    changeSettingParams(){
      this.$network('/api/tools/reulstUpdateMobileParams', {id:this.listQuery.id,mobile_params:{
        
        SolutionSvp: this.$refs.SolutionSvp.getPageParams()
      }})
        .then((res) => {
          this.$message.success('已更新')
        })
        .catch((err) => {
          
        })
    },


    onAnalysisTypeChange(analysisType) {
      this.analySisList = this.analysis[analysisType] || []
      this.onParamsSettingChange('SolutionSvp',true)
    },
    getPlanDetail() {
      this.$network('/api/tools/planDetail', this.listQuery)
        .then((res) => {
          const data = res.data || {}
          this.detail = data.detail || {}
          this.planBase = this.detail.base || 'step4_cvp'
          this.analysis = data.analysis || {}
          
          this.mobileParams =  this.detail.mobile_params || {}
          // console.log(this.detail)

          this.auth_share_state = data.auth_share_state || 0
          this.authTools = data.authTools || []

          this.$nextTick(() => {
            this.getData()
            this.getCvpData()
          })
        })
        .catch((err) => {
          console.error(err)
        })
    },

    getCvpData() {
      // CVP工具I（基于BOM）已保存方案1
      //确定基表--只有历史方案vs历史方案 才会又这项过滤
      let planId = this.detail.step4_cvp || ''
      if (this.detail.step4_cvp2) {
        //确定基表--只有历史方案vs历史方案 才会又这项过滤
        planId = this.detail[this.planBase]
      }
      this.$network('/api/tools/planDetail', { id: planId })
        .then((res) => {
          const data = res.data || {}
          this.cvpDetail = data.detail || {}

          const mixColumnsFnc = (values) => {
            const data = []
            values.map(function (item, index) {
              if (index > 0) {
                item.diffMoney = 0
                data.push(item)
              }
            })
            return data
          }
          this.$nextTick(() => {
            let tableData = null
            let tableData_fixed = null
            let tableData_mix = null
            let tableData_mix_columns = null
            let hasMixData = false

            //固定成本
            this.typePlanData_fixed(planId, (data) => {
              tableData_fixed = data.list || []
              this.diffTableSum(tableData, tableData_fixed, hasMixData, tableData_mix, tableData_mix_columns)
            })

            //混合成本
            this.typePlanData_mix(planId, (data) => {
              hasMixData = true
              tableData_mix_columns = mixColumnsFnc(data.columns || [])
              tableData_mix = data.list || []
              this.diffTableSum(tableData, tableData_fixed, hasMixData, tableData_mix, tableData_mix_columns)
            })

            this.typePlanData('', this.cvpDetail, (data) => {
              tableData = data
              this.diffTableSum(tableData, tableData_fixed, hasMixData, tableData_mix, tableData_mix_columns)
            })
          })
        })
        .catch((err) => {
          console.error(err)
        })
    },

    typePlanData(type, detail = {}, callback) {
      const pageParams = detail.page_params || {}
      const params_tax_per = pageParams.params_tax_per || 0
      const params_sold_per = pageParams.params_sold_per || 0
      const params_cost_per = pageParams.params_cost_per || 0
      const params_pass_per = pageParams.params_pass_per || 0
      const solutionBaseTable = pageParams.solutionBaseTable || {}

      this.$network('/api/tools/planResultData', { id: detail.id, type: 'step-yc' })
        .then((res) => {
          const data = res.data || {}
          const list = data.list || []
          const excelDataChange = []
          list.map(function (item) {
            const no = item.no || ''
            const noPerInfo = solutionBaseTable[no] || {}
            item.params_line_tax_per = noPerInfo.params_line_tax_per || 0
            item.params_line_sold_per = noPerInfo.params_line_sold_per || 0
            item.params_line_cost_per = noPerInfo.params_line_cost_per || 0
            item.params_line_pass_per = noPerInfo.params_line_pass_per || 0
            const afterItem = { ...item }
            for (let key in item) {
              afterItem['__' + key] = item[key]
            }
            excelDataChange.push(afterItem)
          })

          const dataAfter = fnc_excelData_yc_line(list, { params_tax_per, params_sold_per, params_cost_per, params_pass_per })

          callback(dataAfter)
          return dataAfter
        })
        .catch((err) => {
          console.error(err)
        })
    },

    typePlanData_fixed(id, callback) {
      return this.$network('/api/tools/planResultData', {
        id: id,
        type: 'step-fixed'
      })
        .then((res) => {
          const data = res.data || {}
          callback(data)
        })
        .catch((err) => {
          console.error(err)
        })
    },
    typePlanData_mix(id, callback) {
      return this.$network('/api/tools/planResultData', {
        id: id,
        type: 'step-mix'
      })
        .then((res) => {
          const data = res.data || {}
          callback(data)
        })
        .catch((err) => {
          console.error(err)
        })
    },

    diffTableSum(tableData, tableData_fixed, hasMixData, tableData_mix, tableData_mix_columns) {
      if (!tableData || !tableData_fixed || !hasMixData) {
        return
      }

      this.svp_tableData = tableData

      this.svp_tableData_fixed = tableData_fixed

      this.svp_hasMixData = hasMixData

      this.svp_tableData_mix = tableData_mix

      this.svp_tableData_mix_columns = tableData_mix_columns
      this.noticeTableComplete()
    },
    getData() {
      let type = 'step4-yc'

      let queryId = this.listQuery.id
      if (this.detail.step4_cvp2 * 1) {
        type = 'step-yc-origin'

        //确定基表--只有历史方案vs历史方案 才会又这项过滤
        if (this.planBase != 'step4_cvp2') {
          queryId = this.detail.step4_cvp2
        } else {
          queryId = this.detail.step4_cvp
        }
      }
      this.$network('/api/tools/planResultData', { id: queryId, type: type }).then((res) => {
        const data = res.data || {}
        const list = data.list || []
        // console.log(list)
        this.ycTableList = list
        this.noticeTableComplete()
      })
    },
    noticeTableComplete() {
      this.$nextTick(() => {
        if (this.svp_tableData.length && this.ycTableList.length) {
          this.initDataState = 'complete'
        }
      })

      if(!this.initSettingState){
        this.initParamsSetting()
      }

    },
    onChangeParams(e) {
      if (this.detail.id) {
        clearTimeout(this.onChangeParamsTimer)
        this.onChangeParamsTimer = setTimeout(() => {
          this.fncSettingOptions({
            params_tax_per: this.params_tax_per || 0,
            params_sold_per: this.params_sold_per || 0,
            params_cost_per: this.params_cost_per || 0,
            params_pass_per: this.params_pass_per || 0
          })
        }, 300)
      }
    },
    fncSettingOptions(params, returnData = false) {
      // console.log(params)
    }
  }
}

// export default {
//   components: {
//     SliderInputBlock,
//     ToolsItemIntro,
//     SolutionSvp,
//     SolutionAnalysis
//   },
//   data() {
//     return {
//       params_tax_per: 0,
//       params_sold_per: 0,
//       params_cost_per: 0,
//       params_pass_per: 0,
//       initDataState: '',

//       downLoading: false,
//       planType: 'base',
//       planBase: '',
//       baseSvp: '',
//       detail: {},
//       listQuery: {},

//       tableList: [],

//       onChangeParamsTimer: 0,
//       hasActualityData: true,
//       isPrint: false,

//       remark_base: '',
//       remark_extra: '',
//       remark_base_time: '',
//       remark_base_mobile: '',
//       remark_extra_mobile: '',
//       remark_extra_time: '',

//       //// CVP工具I（基于BOM）已保存方案1
//       cvpDetail: {},
//       svp_tableData: [],
//       svp_tableData_fixed: [],
//       svp_tableData_mix: [],
//       svp_tableData_mix_columns: [],
//       scatter_tableData: [],
//       //对比方案数据
//       ycTableList: [],

//       //分析解读
//       analysis: {},
//       analySisList: [],

//       auth_share_state: 0,
//       authTools: []
//     }
//   },
//   mixins: [CVPCalMixIn],
//   created() {
//     this.listQuery.id = this.$route.query.id || 0
//     this.planType = this.$route.query.planType == 'extra' ? 'extra' : 'base'

//     this.getPlanDetail()
//   },
//   computed: {
//     ...mapGetters(['getHasToolAuth']),
//     getDetailName() {
//       return this.detail.name || ''
//     },
//     getWaterName() {
//       return this.detail.water_name || ''
//     },
//     getPlanName() {
//       return this.detail.plan_name || ''
//     },
//     getPlanName_en() {
//       return this.detail.plan_name_en || ''
//     },
//     userInfo() {
//       return this.detail.userInfo || {}
//     },
//     getIsSave() {
//       return this.detail.is_save || 0
//     },
//     getPlanLogo() {
//       return this.detail.plan_logo || ''
//     },
//     getTitle() {
//       return ''
//     }
//   },

//   methods: {
//     onAnalysisTypeChange(analysisType) {
//       this.analySisList = this.analysis[analysisType] || []
//     },
//     getPlanDetail() {
//       this.$network('/api/tools/planDetail', this.listQuery)
//         .then((res) => {
//           const data = res.data || {}
//           this.detail = data.detail || {}
//           this.planBase = this.detail.base || 'step4_cvp'
//           this.analysis = data.analysis || {}

//           this.auth_share_state = data.auth_share_state || 0
//           this.authTools = data.authTools || []

//           this.$nextTick(() => {
//             this.getData()
//             this.getCvpData()
//           })
//         })
//         .catch((err) => {
//           console.error(err)
//         })
//     },

//     getCvpData() {
//       // CVP工具I（基于BOM）已保存方案1
//       //确定基表--只有历史方案vs历史方案 才会又这项过滤
//       let planId = this.detail.step4_cvp || ''
//       if (this.detail.step4_cvp2) {
//         //确定基表--只有历史方案vs历史方案 才会又这项过滤
//         planId = this.detail[this.planBase]
//       }
//       this.$network('/api/tools/planDetail', { id: planId })
//         .then((res) => {
//           const data = res.data || {}
//           this.cvpDetail = data.detail || {}

//           const mixColumnsFnc = (values) => {
//             const data = []
//             values.map(function (item, index) {
//               if (index > 0) {
//                 item.diffMoney = 0
//                 data.push(item)
//               }
//             })
//             return data
//           }
//           this.$nextTick(() => {
//             let tableData = null
//             let tableData_fixed = null
//             let tableData_mix = null
//             let tableData_mix_columns = null
//             let hasMixData = false

//             //固定成本
//             this.typePlanData_fixed(planId, (data) => {
//               tableData_fixed = data.list || []
//               this.diffTableSum(tableData, tableData_fixed, hasMixData, tableData_mix, tableData_mix_columns)
//             })

//             //混合成本
//             this.typePlanData_mix(planId, (data) => {
//               hasMixData = true
//               tableData_mix_columns = mixColumnsFnc(data.columns || [])
//               tableData_mix = data.list || []
//               this.diffTableSum(tableData, tableData_fixed, hasMixData, tableData_mix, tableData_mix_columns)
//             })

//             this.typePlanData('', this.cvpDetail, (data) => {
//               tableData = data
//               this.diffTableSum(tableData, tableData_fixed, hasMixData, tableData_mix, tableData_mix_columns)
//             })
//           })
//         })
//         .catch((err) => {
//           console.error(err)
//         })
//     },

//     typePlanData(type, detail = {}, callback) {
//       const pageParams = detail.page_params || {}
//       const params_tax_per = pageParams.params_tax_per || 0
//       const params_sold_per = pageParams.params_sold_per || 0
//       const params_cost_per = pageParams.params_cost_per || 0
//       const params_pass_per = pageParams.params_pass_per || 0
//       const solutionBaseTable = pageParams.solutionBaseTable || {}

//       this.$network('/api/tools/planResultData', { id: detail.id, type: 'step-yc' })
//         .then((res) => {
//           const data = res.data || {}
//           const list = data.list || []
//           const excelDataChange = []
//           list.map(function (item) {
//             const no = item.no || ''
//             const noPerInfo = solutionBaseTable[no] || {}
//             item.params_line_tax_per = noPerInfo.params_line_tax_per || 0
//             item.params_line_sold_per = noPerInfo.params_line_sold_per || 0
//             item.params_line_cost_per = noPerInfo.params_line_cost_per || 0
//             item.params_line_pass_per = noPerInfo.params_line_pass_per || 0
//             const afterItem = { ...item }
//             for (let key in item) {
//               afterItem['__' + key] = item[key]
//             }
//             excelDataChange.push(afterItem)
//           })

//           const dataAfter = fnc_excelData_yc_line(list, { params_tax_per, params_sold_per, params_cost_per, params_pass_per })

//           callback(dataAfter)
//           return dataAfter
//         })
//         .catch((err) => {
//           console.error(err)
//         })
//     },

//     typePlanData_fixed(id, callback) {
//       return this.$network('/api/tools/planResultData', {
//         id: id,
//         type: 'step-fixed'
//       })
//         .then((res) => {
//           const data = res.data || {}
//           callback(data)
//         })
//         .catch((err) => {
//           console.error(err)
//         })
//     },
//     typePlanData_mix(id, callback) {
//       return this.$network('/api/tools/planResultData', {
//         id: id,
//         type: 'step-mix'
//       })
//         .then((res) => {
//           const data = res.data || {}
//           callback(data)
//         })
//         .catch((err) => {
//           console.error(err)
//         })
//     },

//     diffTableSum(tableData, tableData_fixed, hasMixData, tableData_mix, tableData_mix_columns) {
//       if (!tableData || !tableData_fixed || !hasMixData) {
//         return
//       }

//       this.svp_tableData = tableData

//       this.svp_tableData_fixed = tableData_fixed

//       this.svp_hasMixData = hasMixData

//       this.svp_tableData_mix = tableData_mix

//       this.svp_tableData_mix_columns = tableData_mix_columns
//       this.noticeTableComplete()
//     },
//     getData() {
//       let type = 'step4-yc'

//       let queryId = this.listQuery.id
//       if (this.detail.step4_cvp2 * 1) {
//         type = 'step-yc-origin'

//         //确定基表--只有历史方案vs历史方案 才会又这项过滤
//         if (this.planBase != 'step4_cvp2') {
//           queryId = this.detail.step4_cvp2
//         } else {
//           queryId = this.detail.step4_cvp
//         }
//       }
//       this.$network('/api/tools/planResultData', { id: queryId, type: type }).then((res) => {
//         const data = res.data || {}
//         const list = data.list || []
//         // console.log(list)
//         this.ycTableList = list
//         this.noticeTableComplete()
//       })
//     },
//     noticeTableComplete() {
//       this.$nextTick(() => {
//         if (this.svp_tableData.length && this.ycTableList.length) {
//           this.initDataState = 'complete'
//         }
//       })
//     },
//     onChangeParams(e) {
//       if (this.detail.id) {
//         clearTimeout(this.onChangeParamsTimer)
//         this.onChangeParamsTimer = setTimeout(() => {
//           this.fncSettingOptions({
//             params_tax_per: this.params_tax_per || 0,
//             params_sold_per: this.params_sold_per || 0,
//             params_cost_per: this.params_cost_per || 0,
//             params_pass_per: this.params_pass_per || 0
//           })
//         }, 300)
//       }
//     },
//     fncSettingOptions(params, returnData = false) {
//       // console.log(params)
//     },
//     handleShare() {
//       message.error(`保存后才能进行分享`)
//       this.handleSave(() => {
//         this.$refs.savePlan.close()
//         this.$nextTick(() => {
//           this.$refs.shareLink.open()
//         })
//       })
//     },
//     handleBack() {
//       this.$confirm({
//         content: '是否保存方案',
//         okText: '是',
//         cancelText: '否',
//         onOk: () => {
//           this.handleSave(() => {
//             this.$router.back()
//           })
//         },
//         onCancel: () => {
//           this.$router.back()
//         }
//       })
//     },
//     handleSave(callback) {
//       this.$refs.savePlan.open(
//         this.listQuery.id,
//         {
//           name: this.detail.name || '',
//           remark_base: this.remark_base,
//           params: {
//             params_tax_per: this.params_tax_per,
//             params_sold_per: this.params_sold_per,
//             params_cost_per: this.params_cost_per,
//             params_pass_per: this.params_pass_per
//           }
//         },
//         () => {
//           this.detail.is_save = 1
//           if (callback) {
//             callback()
//           } else {
//             this.$router.replace({ path: '/history' })
//           }
//         }
//       )
//     },

//     async downPDF() {
//       this.downLoading = true
//       this.isPrint = true
//       this.$refs.pageLoading.open('正在进行方案下载')
//       //增加打印次数
//       this.handlePrint()

//       setTimeout(() => {
//         this.$nextTick(async () => {
//           this.$refs.pageLoading.text('方案构件中...')

//           let elId = 'solutionTemplatePDF'
//           let pdfName = '基础方案'
//           await this.$refs.solutionTemplatePDF.downPDF(this)
//           this.$refs.pageLoading.text('正在生成PDF文件')
//           await htmlToPDF(elId, pdfName, 'PDF-foot')
//           this.$refs.pageLoading.text('下载成功')
//           setTimeout(() => {
//             location.reload()
//           }, 800)
//         })
//       }, 200)

//       return
//     },
//     handlePrint() {
//       this.$network('/api/tools/planPrintTimes', { id: this.listQuery.id, tool_type: 'two' })
//     }
//   }
// }
</script>
